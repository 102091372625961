import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import {
  apiGetCurrentStudent,
  apiGetModifiedStudents,
  apiGetByClassGroups
} from '../api/apiCommands'

export const getStudentsModifedState = createAsyncThunk(
  'students/getStudentsModifedState',
  async ({token, url}) => {
    return apiGetModifiedStudents(token, url)
  }
)

export const getCurrentStudent = createAsyncThunk(
  'students/getCurrentStudent',
  async ({token, studentId}) => {
    return apiGetCurrentStudent(token, studentId)
  }
)

export const getStudentsByClassGroups = createAsyncThunk(
  'students/getStudentsByClassGroups',
  async ({token, classId}) => {

    return apiGetByClassGroups(token, classId)
  }
)

const initialState = {
  totalStudents: 0,
  filteredStudents: 0,
  items: [],
  currentStudent: null,
  status: null,
  error: null,
  studentsByClassGroups: [],
}

const studentSlice = createSlice({
  name: 'students',

  initialState,

  reducers: {
    setNewStatus(state, action) {
      state.status = action.payload
    },

    nullifySubjects(state) {
      state.totalStudents = initialState.totalStudents
      state.filteredStudents = initialState.filteredStudents
      state.items = initialState.items
      state.status = initialState.status
      state.error = initialState.error
    }
  },

  extraReducers: {
    [getStudentsModifedState.pending]: (state, action) => {
      state.status = 'loading'
    }, [getCurrentStudent.pending]: (state, action) => {
      state.status = 'loading'
    },
    [getStudentsByClassGroups.pending]: (state, action) => {
      state.status = 'loading'
    },

    [getStudentsByClassGroups.fulfilled]: (state, action) => {
      const response = action.payload

      if (response.fetchStatus) {
        state.error = 401
      } else {
        state.studentsByClassGroups = response
        state.status = 'success'
        state.error = null
      }
    },

    [getStudentsModifedState.fulfilled]: (state, action) => {
      const response = action.payload

      if (response.fetchStatus) {
        state.error = 401
      } else {
        state.totalStudents = response.totalItems
        state.filteredStudents = response.filteredItems
        state.items = response.items
        state.status = 'success'
        state.error = null
      }
    },

    [getStudentsModifedState.rejected]: (state, action) => {
      state.status = 'failed'
      state.error = action.error.message
    },

    [getCurrentStudent.rejected]: (state, action) => {
      state.status = 'failed'
      state.error = action.error.message
    },

    [getStudentsByClassGroups.rejected]: (state, action) => {
      state.status = 'failed'
      state.error = action.error.message
    },
  }
})

export default studentSlice.reducer
export const {setNewStatus, nullifySubjects} = studentSlice.actions

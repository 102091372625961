import React, { Fragment, useEffect, useRef, useState } from 'react'
import { useTranslation } from "react-i18next"
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import classnames from 'classnames'
import { CSSTransition } from 'react-transition-group'

import { LogoutButton } from '../common/util-components/logoutButton'
import { NotificationComponent } from './notificatioPage/notification'

import { ReactComponent as ReactSearchSvg } from '../../assets/icon-search.svg'
import { ReactComponent as ReactTranslatingSvg } from '../../assets/icon-translating.svg'
import { ReactComponent as ReactBurgerSvg } from '../../assets/icon-menu-burger.svg'
import ReactProfileSvg from '../../assets/icon-default-profile.svg'

export const HeaderComponent = ({changeLanguage, overflowProperties, changeAsideStatus, pageHeader}) => {
  const { i18n, t } = useTranslation()
  const languageRef = useRef(null)
  const userRef = useRef(null)
  const userName = useSelector(state => state.userData.userName)
  const userEmail = useSelector(state => state.userData.userEmail)
  const userRole = useSelector(state => state.userData.userRole)
  const [languageIsOpen, setLanguageIsOpen] = useState(false)
  const [userIsOpen, setUserIsOpen] = useState(false)
  const [searchIsOpen, setSearchIsOpen] = useState(false)

  useEffect(() => {
    return function cleanup() {
      closeUserPopup()
    }
    // eslint-disable-next-line
  }, [])

  const changeLanguageHandler = (language) => {
    changeLanguage(language)
    setLanguageIsOpen(!languageIsOpen)
    localStorage.setItem('language', language)
    document.removeEventListener('click', closeLanguagePopup)
  }

  const openLanguagePopup = () => {
    if (!languageIsOpen) {
      document.addEventListener('click', closeLanguagePopup)
    } else {
      document.removeEventListener('click', closeLanguagePopup)
    }

    setLanguageIsOpen(!languageIsOpen)
  }

  const closeLanguagePopup = (evt) => {
    var path = evt.path || (evt.composedPath && evt.composedPath())
    if (path) {
      if (!path.includes(languageRef.current)) {
        setLanguageIsOpen(false)
        document.removeEventListener('click', closeLanguagePopup)
      }
    }
  }

  const openUserPopup = () => {
    if (!userIsOpen) {
      document.addEventListener('click', closeUserPopupHandler)
      document.addEventListener(`keydown`, escPressHandler)
    } else {
      document.removeEventListener('click', closeUserPopupHandler)
      document.removeEventListener(`keydown`, escPressHandler)
    }

    setUserIsOpen(!userIsOpen)
  }

  const closeUserPopupHandler = (evt) => {
    var path = evt.path || (evt.composedPath && evt.composedPath())

    if (path) {
      if (!path.includes(userRef.current)) {
        closeUserPopup()
      }
    }
  }

  const escPressHandler = (evt) => {
    if (evt.keyCode === 27) {
      closeUserPopup()
    }
  }

  const closeUserPopup = () => {
    setUserIsOpen(false)
    document.removeEventListener('click', closeUserPopupHandler)
    document.removeEventListener(`keydown`, escPressHandler)
  }

  return (
    <Fragment>
      <h1 className={classnames("header__heading", {"header__heading--hidden": searchIsOpen})}>{pageHeader} </h1>

      <div className="header__interactive-container">
        <div className="header__search-container">
          <div className="header__search-bar" 
            onFocus={() => setSearchIsOpen(true)}
            onBlur={() => setSearchIsOpen(false)}
          >
            <input
              className="header__search"
              type="text" name="header__search"
              aria-label="search" tabIndex={overflowProperties ? -1 : 0}
            />
            <button className="header__search-button" type="button" tabIndex={overflowProperties ? -1 : 0}>
              <ReactSearchSvg />
              Search
            </button>
          </div>
        </div>

        <NotificationComponent overflowProperties={overflowProperties} />

        <div className="header__language-container">
          <button
            className={classnames("header__language-button",
              {"header__language-button--active": languageIsOpen}
            )}
            type="button" onClick={() => openLanguagePopup()}
            ref={languageRef}
            tabIndex={overflowProperties ? -1 : 0}
          >
            <ReactTranslatingSvg />
            {i18n.language === 'en' ? `English (${i18n.language})` : `Deutsch (${i18n.language})`}
          </button>
          <CSSTransition in={languageIsOpen} timeout={250} classNames="popup-animation" unmountOnExit>
            <div className="header__language-selector">
                <button type="button" onClick={() => changeLanguageHandler("en")} tabIndex={overflowProperties ? -1 : 0} disabled={i18n.language === 'en'}>
                  EN
                </button>
                <button type="button" onClick={() => changeLanguageHandler("de")} tabIndex={overflowProperties ? -1 : 0} disabled={i18n.language === 'de'}>
                  DE
                </button>
              </div>
          </CSSTransition>
        </div>
      </div>

      <div className="header__user-container" ref={userRef}>
        <button 
          className="header__user-popup-button"
          type="button"
          onClick={() => openUserPopup()}
          tabIndex={overflowProperties ? -1 : 0}
        >
          User Profile
          <span className="header__user-name">{userName}</span>
          <span className="header__user-avatar">
            {/* <img src={ReactProfileSvg} alt="user avatar" width="36" height="36"></img> */}
          </span>
        </button>

        <CSSTransition in={userIsOpen} timeout={250} classNames="popup-animation" unmountOnExit>
          <div className="header__user-dialog-container">
            <span className="header__user-avatar">
              <img src={ReactProfileSvg} alt="user avatar" width="36" height="36"></img>
            </span>
            <span className="header__user-name">{userName}</span>
            <span className="header__user-email">{userEmail}</span>

            <div className="header__buttons-container">
              {userRole === "app-role-teacher" && 
                <Link className="header__user-link" to="/teacher/profile" onClick={() => {closeUserPopup()}}>
                  {t(`manage`)}
                </Link>
              }
              <LogoutButton className="header__user-button" />
            </div>
          </div>
        </CSSTransition>
      </div>

      <button className="header__menu-button" onClick={() => changeAsideStatus(true)}>
        <ReactBurgerSvg />
        Menu
      </button>
    </Fragment>
  )
}

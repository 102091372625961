import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { apiGetAdditionalHours } from "../api/apiCommands"

export const getAdditionalHours = createAsyncThunk(
  'additionalHours/getAdditionalHours',
  async ({token, url}) => {
    return apiGetAdditionalHours(token, url)
  }
)

const initialState = {
  totalItems: 0,
  filteredItems: 0,
  items: [],
  status: null,
  error: null
}

const additionalHourSlice = createSlice({
  name: 'additionalHours',

  initialState,

  reducers: {
      setNewStatus(state, action) {
        state.status = action.payload
      },

      nullifyModules(state) {
        state.totalItems = initialState.totalItems
        state.filteredItems = initialState.filteredItems
        state.items = initialState.items
        state.status = initialState.status
        state.error = initialState.error
      }
    },

    extraReducers: {
      [getAdditionalHours.pending]: (state) => {
        state.status = 'loading'
      },

      [getAdditionalHours.fulfilled]: (state, action) => {
        const response = action.payload

        state.items = response.items
        state.totalItems = response.totalItems
        state.filteredItems = response.filteredItems
        state.status = 'success'
        state.error = null
      },

      [getAdditionalHours.rejected]: (state, action) => {
        state.status = 'failed'
        state.error = action.error.message
      },
    }
})

export default additionalHourSlice.reducer
export const { setNewStatus, nullifyModules } = additionalHourSlice.actions

import React from "react"
import {useTranslation} from "react-i18next"

export const HoursInfo = ({ activeHours, statistics, temporaryData }) => {
  const {t} = useTranslation()

  const currentTypeHoursStat = temporaryData.reduce((accumulator, currentValue) => accumulator + currentValue, 0)
  const plannedHours = statistics.reduce((accumulator, currentValue) => accumulator + currentValue.totalHours, 0)

  return (
    <div className="dashboard__worked-hours-info">
      <div className="dashboard__worked-hours">
        <div className="dashboard__worked-hours-text">
          {activeHours === 'worked' ? t(`dashboardPage.workedHours`) : t(`dashboardPage.absentHours`)}
        </div>
        <div className="dashboard__worked-hours-stats">
          {currentTypeHoursStat} <span className="dashboard__worked-hours-out-of">/ {plannedHours}</span>
        </div>
      </div>
      <div className="dashboard__planned-hours">
        <div className="dashboard__planned-hours-text">
          {t(`dashboardPage.plannedHours`)}
        </div>
        <div className="dashboard__planned-hours-stats">
          {plannedHours}
        </div>
      </div>
    </div>
  )
}

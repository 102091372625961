import React, {useContext, useEffect, useState} from "react";
import {Controller, useForm} from "react-hook-form";
import Select from "react-select";
import classnames from "classnames";
import InputMask from "react-input-mask";
import {useDispatch, useSelector} from "react-redux";
import {useTranslation} from "react-i18next";

import { VoidComponent } from "../../common/voidComponent"
import {makeOptionsList} from "../../../utils/utils"
import {LoadingComponent} from "../../common/loadingComponents/loadingComponent"
import {LoadingButtonComponent} from "../../common/loadingComponents/loadingButtonComponent"
import {PageContext} from "../../../pages/pageContext"
import {getProfileState} from "../../../redux/profileSlice"
import {getSelectorState} from "../../../redux/selectorSlice"
import {apiUpgradeProfile} from "../../../api/apiCommands"

import {ReactComponent as ReactCloseSvg} from "../../../assets/icon-close.svg"

export const ProfilePageComponent = () => {
  const dispatch = useDispatch()
  const {t} = useTranslation()

  const successToken = useSelector(state => state.userData.successToken)
  const userError = useSelector(state => state.profileData.error)
  const selectorProperties = useSelector(state => state.selectorData)
  const [profileStatus, setProfileStatus] = useState(null)
  const [isSuccessStatus, setIsSuccessStatus] = useState(null)

  const {setPageHeader} = useContext(PageContext)
  const [isPending, setIsPending] = useState(false)

  const teacherFormState = {
    teacherSalutation: null,
    teacherFirstName: "",
    teacherLastName: "",
    teacherEmail: "",
    teacherAddress: "",
    teacherPhone: "",
    teacherZip: "",
    teacherCity: "",
    teacherLessonSalary: "",
    teacherHourlySalary: ""
  }

  const salutations = makeOptionsList(t, selectorProperties.salutations)
  const {register, handleSubmit, formState: {errors}, control, reset} = useForm({criteriaMode: "all", teacherFormState})

  useEffect(() => {
    setPageHeader('Profile')
    setIsPending(true)
    dispatch(getProfileState(successToken)).then((response) => {
      if (response.payload) {
        dispatch(getSelectorState({token: successToken, type: 8})).then(() => {
          reset({
            teacherSalutation: response.payload.salutation ? {
              value: response.payload.salutation.value,
              label: t(`selectorOptions.${response.payload.salutation.localizationKey}`)
            } : null,
            teacherFirstName: response.payload.firstName ? response.payload.firstName : "",
            teacherLastName: response.payload.lastName ? response.payload.lastName : "",
            teacherEmail: response.payload.email ? response.payload.email : "",
            teacherAddress: response.payload.address ? response.payload.address : "",
            teacherPhone: response.payload.phone ? response.payload.phone : "",
            teacherZip: response.payload.zip ? response.payload.zip : "",
            teacherCity: response.payload.city ? response.payload.city : "",
          })
          setIsPending(false)
        })
      }
    })

    // eslint-disable-next-line
  }, [dispatch, successToken])

  const submitTeacherHandler = (data) => {
    setIsPending(true)
    const changedData = {
      email: data.teacherEmail ? data.teacherEmail : "",
      firstName: data.teacherFirstName ? data.teacherFirstName : "",
      lastName: data.teacherLastName ? data.teacherLastName : "",
      phone: data.teacherPhone ? data.teacherPhone : "",
      salutation: data.teacherSalutation ? data.teacherSalutation.value : "",
      address: data.teacherAddress ? data.teacherAddress : "",
      city: data.teacherCity ? data.teacherCity : "",
      zip: data.teacherZip ? data.teacherZip : "",
    }

    apiUpgradeProfile(successToken, changedData).then(() => {
      dispatch(getProfileState(successToken)).then(() => {
        setIsPending(false)
        setIsSuccessStatus(true)
        setProfileStatus('Profile information has been successfully updated')
      })
    })
      .catch((error) => {
        setIsPending(false)
        setIsSuccessStatus(false)
        setProfileStatus(t(`errors.${error.response.data.errors[0]}`))
      })
  }

  return (
    <div className="teacher__profile-container">
      {isPending && !userError
        ? <div className="teacher__loading loading">
          <LoadingComponent/>
        </div>
        : userError ? <VoidComponent error={userError} /> :
        <form className="teacher__form" onSubmit={handleSubmit(submitTeacherHandler)}>
          <fieldset className="teacher__fieldset">
            <ul className="teacher__input-list">
              <li className="teacher__input-item input-container">
                <label htmlFor="teacherSalutation">
                  {t(`inputLabels.labelSalutation`)}
                  <span className="input-container__requre">*</span>
                </label>
                <Controller
                  name="teacherSalutation"
                  control={control}
                  rules={{required: true}}
                  render={({field}) => (
                    <Select
                      className={classnames(
                        "input__selector-container",
                        {"input__selector-container--error": errors.teacherSalutation},
                        {"input__selector-container--full": field.value})
                      }
                      classNamePrefix="input-select"
                      id="teacherSalutation"
                      {...field}
                      options={salutations}
                      isDisabled={salutations.length === 0}
                      placeholder={`${t(`inputLabels.placeholderChoose`)} ${t(`inputLabels.labelSalutation`)}`}
                    />
                  )}
                />
                {errors.teacherSalutation &&
                <span className="input-container__validation">{t(`inputLabels.validationRequer`)}</span>}
              </li>
              <li className="teacher__input-item input-container">
                <label htmlFor="teacherFirstName">
                  {t(`inputLabels.labelFirstName`)}
                  <span className="input-container__requre">*</span>
                </label>
                <input
                  type="text"
                  name="teacherFirstName"
                  id="teacherFirstName"
                  className={classnames(
                    "input-container__input",
                    {"input-container__input--error": (errors.teacherFirstName || errors?.teacherFirstName?.type === "pattern")})
                  }
                  placeholder={`${t(`inputLabels.placeholderEnter`)} ${t(`inputLabels.labelFirstName`)}`}
                  {...register("teacherFirstName", {required: true, pattern: /^[^0-9]+$/i})}
                />
                {(errors?.teacherFirstName?.type === "pattern" || errors.teacherFirstName) &&
                (<span className="input-container__validation">{t(`inputLabels.validationAlphabetical`)}</span>)
                }
              </li>
              <li className="teacher__input-item input-container">
                <label htmlFor="teacherLastName">
                  {t(`inputLabels.labelLastName`)}
                  <span className="input-container__requre">*</span>
                </label>
                <input
                  type="text"
                  name="teacherLastName"
                  id="teacherLastName"
                  className={classnames(
                    "input-container__input",
                    {"input-container__input--error": (errors.teacherLastName || errors?.teacherLastName?.type === "pattern")})
                  }
                  placeholder={`${t(`inputLabels.placeholderEnter`)} ${t(`inputLabels.labelLastName`)}`}
                  {...register("teacherLastName", {required: true, pattern: /^[^0-9]+$/i})}
                />
                {(errors?.teacherLastName?.type === "pattern" || errors.teacherLastName) &&
                (<span className="input-container__validation">{t(`inputLabels.validationAlphabetical`)}</span>)
                }
              </li>
              <li className="teacher__input-item input-container">
                <label htmlFor="teacherEmail">
                  {t(`inputLabels.labelEmail`)}
                  <span className="input-container__requre">*</span>
                </label>
                <input
                  type="email"
                  name="teacherEmail"
                  id="teacherEmail"
                  className={classnames(
                    "input-container__input",
                    {"input-container__input--error": (errors.teacherEmail || errors?.teacherEmail?.type === "pattern")})
                  }
                  placeholder={`${t(`inputLabels.placeholderEnter`)} ${t(`inputLabels.labelEmail`)}`}
                  {...register("teacherEmail", {required: true, pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i})}
                />
                {(errors?.teacherEmail?.type === "pattern" || errors.teacherEmail) &&
                (<span className="input-container__validation">{t(`inputLabels.validationEmail`)}</span>)
                }
              </li>
              <li className="teacher__input-item input-container">
                <label htmlFor="teacherAddress">
                  {t(`inputLabels.labelAdress`)}
                </label>
                <input
                  type="text"
                  name="teacherAddress"
                  id="teacherAddress"
                  className={classnames("input-container__input", {"input-container__input--error": errors.teacherAddress})}
                  placeholder={`${t(`inputLabels.placeholderEnter`)} ${t(`inputLabels.labelAdress`)}`}
                  {...register("teacherAddress", {required: false})}
                />
                {errors.teacherAddress &&
                <span className="input-container__validation">{t(`inputLabels.validationRequer`)}</span>}
              </li>
              <li className="teacher__input-item input-container">
                <label htmlFor="teacherPhone">
                  {t(`inputLabels.labelPhoneNumber`)}
                </label>
                <Controller
                  control={control}
                  name="teacherPhone"
                  rules={{pattern: {value: /^\d{3} ?\d{3} ?\d{2} ?\d{2}$/i}}}
                  render={({field: {onChange, value = ""}}) => (
                    <InputMask
                      mask="999 999 99 99"
                      value={value}
                      onChange={onChange}>
                      {(inputProps) => (
                        <input
                          className={classnames("input-container__input",
                            {"input-container__input--error": (errors.teacherPhone || errors?.teacherPhone?.type === "pattern")})}
                          {...inputProps}
                          type="tel"
                          placeholder={`${t(`inputLabels.placeholderEnter`)} ${t(`inputLabels.labelMobileNumber`)}`}
                          id="teacherPhone"
                        />
                      )}
                    </InputMask>
                  )}
                />
                {(errors?.teacherPhone?.type === "pattern" || errors.teacherPhone) &&
                (<span className="input-container__validation">{t(`inputLabels.validationPhone`)}</span>)
                }
              </li>
              <li className="teacher__input-item teacher__input-item--short input-container">
                <label htmlFor="teacherZip">
                  {t(`inputLabels.labelZipCode`)}
                </label>
                <input
                  type="text"
                  name="teacherZip"
                  id="teacherZip"
                  className={classnames("input-container__input", {"input-container__input--error": errors.teacherZip})}
                  placeholder={`${t(`inputLabels.placeholderEnter`)} ${t(`inputLabels.labelZipCode`)}`}
                  {...register("teacherZip", {required: false})}
                />
                {errors.teacherZip &&
                <span className="input-container__validation">{t(`inputLabels.validationRequer`)}</span>}
              </li>
              <li className="teacher__input-item input-container">
                <label htmlFor="teacherCity">
                  {t(`inputLabels.labelCity`)}
                </label>
                <input
                  type="text"
                  name="teacherCity"
                  id="teacherCity"
                  className={classnames("input-container__input", {"input-container__input--error": errors.teacherCity})}
                  placeholder={`${t(`inputLabels.placeholderEnter`)} ${t(`inputLabels.labelCity`)}`}
                  {...register("teacherCity", {required: false})}
                />
                {errors.teacherCity &&
                <span className="input-container__validation">{t(`inputLabels.validationRequer`)}</span>}
              </li>
            </ul>
          </fieldset>

          <div className="teacher__button-container">
            <button className="teacher__button button" type="submit" disabled={isPending}>
              {!isPending ?
                t(`teachersPage.save`)
                : <LoadingButtonComponent/>
              }
            </button>
          </div>
        </form>}
      {profileStatus &&
      <div className={classnames(
        {"teacher__error-container": !isSuccessStatus},
        {"teacher__success-container": isSuccessStatus})
      }>
        <span>{profileStatus}</span>
        <button className="teacher__error-close" onClick={() => {
          setProfileStatus(null)
          setIsSuccessStatus(null)
        }}>
          <ReactCloseSvg/>
          Close
        </button>
      </div>}
    </div>
  )
}
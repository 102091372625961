import { UserAgentApplication } from "msal"
import {createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import { azureConfig, scopes } from '../azure/azureConfig'

const userAgentApplication = new UserAgentApplication(azureConfig)

export const getUserState = createAsyncThunk(
  'userData/getUserState',
  async () => {
    return userAgentApplication.acquireTokenSilent({scopes})
  }
)

const initialState = {
  userName: null,
  userRole: null,
  userEmail: null,
  homeAccountIdentifier: null,
  successToken: null,
  idToken: null,
  status: null,
  error: null,
}

const userSlice = createSlice({
  name: 'userData',

  initialState,
  
  reducers: {
    setNewStatus(state, action) {
      state.status = action.payload
    },

    nullifyUserState(state) {
      state.userRole = null
      state.token = null
      state.refreshToken = null
      state.status = null
    },

    setNewState(state, action) {
      const response = action.payload
      const claims = action.payload.idToken.claims

      state.userName = response.account.name
      state.userRole = claims.roles[0]
      state.homeAccountIdentifier = response.account.homeAccountIdentifier
      state.successToken = response.accessToken
      state.idToken =  response.idToken.rawIdToken
      state.status = 'success'
      state.error = null
    }
  },

  extraReducers: {
    [getUserState.pending]: (state, action) => {
      state.status = 'loading'
    },

    [getUserState.fulfilled]: (state, action) => {
      const response = action.payload
      const claims = action.payload.idToken.claims

      state.userName = response.account.name
      state.userEmail = response.account.userName
      state.userRole = claims?.roles?.[0]
      state.homeAccountIdentifier = response.account.homeAccountIdentifier
      state.successToken = response.accessToken
      state.idToken = response.idToken.rawIdToken
      state.status = 'success'
      state.error = null
    },

    [getUserState.rejected]: (state, action) => {
      state.status = 'failed'
      state.error = action.error.message
    },
  }
})

export default userSlice.reducer
export const { setNewStatus, nullifyUserState, setNewState } = userSlice.actions
import { UserAgentApplication } from "msal"

import { store } from '../index'
import { BASE_URL } from './apiConfig'
import { azureConfig, scopes } from '../azure/azureConfig'
import { getUserState } from '../redux/userSlice'

const axios = require('axios')
const axiosApiInstance = axios.create()

// ------------  Response interceptor for API calls ------------ \\

axiosApiInstance.interceptors.response.use((response) => {
  return response.data
}, async function async(error) {

  if (error.response && error.response.status === 401) {
    const userAgentApplication = new UserAgentApplication(azureConfig)
  
    await store.dispatch(getUserState())
    .catch(() => {
      userAgentApplication.loginPopup({scopes}).then((response) => {
        localStorage.setItem('userRole', response?.idToken?.claims?.roles[0])
      })
    })
    console.log('401 interceptor')
    return axios.request(error.config);
  }

  return Promise.reject(error);
})

// ------------ Get request ------------ \\

export const apiGetTemplate = async (token, route) => {
  const response = axiosApiInstance.get(`${BASE_URL}/api/${route}`, {
    trusty: true,
    insecure: true,
    headers: {
      Authorization: `Bearer ${token}`
    }
  })

  return response
}

// ------------ Get current request ------------ \\

export const apiGetCurrentTemplate = async (token, route, objectId) => {
  const response = axiosApiInstance.get(`${BASE_URL}/api/${route}/${objectId}`, {
    trusty: true,
    insecure: true,
    headers: {
      Authorization: `Bearer ${token}`
    }
  })

  return response
}

// ------------ Get request with custom options (filter, order) ------------ \\

export const apiGetTemplateModifed = async (token, route, url= null) => {
  let urlString = `${BASE_URL}/api/${route}`

  if (url) {
    urlString = `${BASE_URL}/api/${route}?${url}`
  }

  const response = axiosApiInstance
  .get(urlString, {
    trusty: true,
    insecure: true,
    headers: {
      Authorization: `Bearer ${token}`
    }
  })

  return response
}

// ------------ Get by few routs ------------ \\

export const apiGetByFewRoutsTemplate = async (token, route, action, objectId = null) => {
  let response

  if (objectId) {
    response = axiosApiInstance.get(`${BASE_URL}/api/${route}/${action}/${objectId}`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
  } else {
    response = axiosApiInstance.get(`${BASE_URL}/api/${route}/${action}/`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
  }

  return response
}

// ------------ Get request by type ------------ \\

export const apiGetTemplateByType = async (token, route, type) => {
  const response = axiosApiInstance.get(`${BASE_URL}/api/${route}?types=${type}`, {
    trusty: true,
    insecure: true,
    headers: {
      Authorization: `Bearer ${token}`
    }
  })

  return response
}

// ------------ Post request ------------ \\

export const apiPostTemplate = async (token, route, newPost) => {
  const response = axiosApiInstance.post(`${BASE_URL}/api/${route}`, newPost, {
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
    }
  })

  return response
}

export const apiPostNullTemplate = async (token, route, newPost) => {
  const response = fetch(`${BASE_URL}/api/${route}`, {
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json; charset=utf-8'
    },
    method: "POST",
    body: newPost
  })

  return response
}

export const apiPostTemplateTwoRouts = async (token, route, route2, newPost) => {
  const response = axiosApiInstance.post(`${BASE_URL}/api/${route}/${route2}`, newPost, {
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
    }
  })

  return response
}

export const apiPostTemplateConfirm = async (token, route, action, item, id) => {
  const response = axiosApiInstance.post(`${BASE_URL}/api/${route}/${action}/${id}`, null, {
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
    }
  })

  return response
}

// ------------ Post file request ------------ \\

export const apiPostFilesTemplate = async (token, route, newPost) => {
  const response = axiosApiInstance.post(`${BASE_URL}/api/${route}`, newPost, {
    headers: {
      Authorization: `Bearer ${token}`,
      ContentType: 'multipart/form-data',
    }
  })

  return response
}

// ------------ Put (change) request ------------ \\

export const apiChangeTemplate = async (token, route, newChange) => {
  const response = axiosApiInstance.put(`${BASE_URL}/api/${route}`, newChange, {
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
    }
  })

  return response
}

// ------------ Delete request ------------ \\

export const apiDeleteTemplate = async (token, route, objectId) => {
  const response = axiosApiInstance.delete(`${BASE_URL}/api/${route}/${objectId}`, {
    trusty: true,
    insecure: true,
    headers: {
      Authorization: `Bearer ${token}`
    }
  })

  return response
}


// ------------ Change something request ------------ \\

export const apiChangeSomethingTemplate = async (token, route, action, objectId) => {
  const response = axiosApiInstance.post(`${BASE_URL}/api/${route}/${action}/${objectId}`, null, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  })

  return response
}

// ------------ Download something request ------------ \\

export const apiDownloadSomethingTemplate = async ({
  token,
  route,
  url = null,
  setPercentage,
  setProgress,
  catchFunction = () => {},
  fileName,
  fileType = 'pdf'
}) => {
  let urlString = `${BASE_URL}/api/${route}`
  setProgress('in-progress')

  if (url) {
    urlString = `${BASE_URL}/api/${route}?${url}`
  }

  axiosApiInstance.get(urlString, {
    onDownloadProgress: (progressEvent) => {
      let percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
      setPercentage(percentCompleted)
    },
    headers: {
      Authorization: `Bearer ${token}`,
    },
    responseType: 'blob',
  })
  .then((response) => {
    setProgress('finished')
    const url = window.URL.createObjectURL(response);
    const link = document.createElement('a')
    link.href = url;
    link.setAttribute('download', `${fileName}.${fileType}`)
    document.body.appendChild(link)
    link.click()
    link.parentNode.removeChild(link)
  
    setTimeout(function () {
      window.URL.revokeObjectURL(url)
    }, 100)
  })
  .catch((error) => {
    catchFunction(error?.response?.statusText)
    setProgress('error')
  })
}
// --- variables for special settings/select requests for apiGetSelectOptions() method

export const CONTROLS = {
  ctrlDown: false,
  ctrlKey: 17,
  cmdKey: 91,
  vKey: 86,
  cKey: 67,
  esc: 27,
  delete: 46
}

export const SETTINGS_SELECT = {
  COUNTRIES_REQUEST : 1,
  STUDENT_TYPES_REQUEST : 2,
  LESSON_TYPES_REQUEST : 4,
  SALUTATIONS_REQUEST : 8,
  DECLINE_REASONS_REQUEST : 16,
  NATIONALITIES_REQUEST : 32,
  ATTENDANCE_TYPES_REQUEST : 64,
  CLASSES_REQUEST : 128,
  ROOMS_REQUEST : 256,
  TEACHERS_REQUEST : 512,
  SEMESTERS_REQUEST : 1024,
  SUBJECTS_REQUEST : 2048,
  LESSON_RUNNING_TYPES: 4096,
  SEMESTER_TYPES: 8192
}

export const ERROR_DETAIL_KEY = {
  TEACHER_IS_BUSY: 'affectedTeacher',
  SECOND_TEACHER_IS_BUSY: 'affectedTeacher',
  CLASS_HAS_ANOTHER_LESSON: 'affectedClass',
  GROUP_HAS_ANOTHER_LESSON: 'affectedClassGroup',
  ROOM_IS_OCCUPIED: 'affectedRoom',
  ANOTHER_SEMESTER_IN_THIS_PERIOD: 'semester'
}

export const LESSON_TYPES = {
  NORMAL: 0,
  HOURLY: 1
}

export const EDUCATION_TYPES = {
  APPRENTICE: 0,
  FULL_TIME: 1,
  FURTHER: 2
}

export const LESSON_RUNNING_TYPES = {
  ONLINE: 0,
  ROOM: 1,
  OPEN: 2
}

export const SEMESTER_TYPES = {
  APPRENTICE: 0,
  FULL_TIME: 0,
  FURTHER: 1,
  APPRENTICE_EDUCATION: 0,
  FULL_TIME_EDUCATION: 0,
  FURTHER_EDUCATION: 1,
}

export const ATTENDDANCE_TYPES = {
  PRESENT: 0,
  ABSENT: 1,
  EXCUSED: 2
}

export const ATTENDDANCE_NAMES = {
  0: "present",
  1: "absent",
  2: "excused"
}

export const EXAM_TYPES = {
  NO_EXAM: false,
  EXAM: true,
}

export const WEEK_TYPES = {
  EVERY_WEEK: 0,
  ODD_WEEK: 1,
  EVEN_WEEK: 2
}

export const NOTIFICATION_STATUS = {
  0: "InProgress",
  1: "Failed",
  2: "Finished"
}

export const NOTIFICATION_ITEM_STATUS = {
  0: "Processing",
  1: "Processed",
  2: "Failed",
  3: "Skipped"
}

export const NOTIFICATION_TYPE = {
  1: "Attendance",
  2: "Grade"
}

export const GRADES_CONST = {
  miniStep: 0.05,
  step: 1,
  minValue: 1,
  maxValue: 6
}

export const FORM_LESSONS_MODES = {
  LESSONS_NOT_ALTERNTATE: "notAlt",
  LESSONS_ALTERNATE: "alt",
  LESSONS_PER_TWO_WEEKS: "perTwoWeeks"
}

export const FILES_TYPES = {
  WORD: 'word',
  PDF: 'pdf',
  EXCEL: 'excel'
}

export const PROOF_GRADES = {
  0: {
    description: '- -',
    color: '#c20000',
    value: 1
  },
  1: {
    description: '-',
    color: '#f9a03f',
    value: 2
  },
  2: {
    description: '+',
    color: '#ffcb47',
    value: 3
  },
  3: {
    description: '++',
    color: '#7ee00e',
    value: 4
  },
  4: {
    description: '+++',
    color: '#1ec103',
    value: 5
  },
}

export const PROOF_GRADES_LIST = [
  {
    description: '- -',
    color: '#c20000',
    value: 1
  },
  {
    description: '-',
    color: '#f9a03f',
    value: 2
  },
  {
    description: '0',
    color: '#f9a03f',
    value: 0
  },
  {
    description: '+',
    color: '#ffcb47',
    value: 3
  },
  {
    description: '++',
    color: '#7ee00e',
    value: 4
  },
  {
    description: '+++',
    color: '#1ec103',
    value: 5
  }
]

export const PROOF_STATUTES = {
  PROOF_CREATED: 0,
  PROOF_FILLED_BY_TEACHER: 1,
  PROOF_FILL_LINK_SENT_BY_EMAIL: 2,
  PROOF_FILLED_BY_STUDENT: 3,
  PROOF_APPROVED_BY_TEACHER: 4,
  PROOF_APPROVE_LINK_SENT_BY_EMAIL: 5,
  PROOF_APPROVED_BY_STUDENT: 6,
  PROOF_PDF_SENT_BY_EMAIL: 7
}

export const USER_ROLE_MAP = {
  'app-role-admin': 'admin',
  'app-role-teacher': 'teacher',
  'app-role-student': 'student'
}

export const DISPLAY_DATE_FORMAT = 'dd.MM.yyyy'

export const ITEMS_PER_PAGE = 10
export const SIBLING_COUNT = 1

export const NOTIFICATION_EVENT_STATUS = {
  UNKNWON: 0,
  PROCESSED: 1,
  DROPPED: 2,
  DEFERRED: 3,
  BOUNCED: 4,
  DELIVERED: 5
}

export const NOTIFICATION_EMAIL_STATUS = {
  INPROGRESS: 0,
  FAILED: 1,
  FINISHED: 2,
}

export const NOTIFICATION_NOTES = {
  COMPLETED_SUCCESSFULLY: 'COMPLETED_SUCCESSFULLY',
  INTERNAL_SERVER_ERROR: 'INTERNAL_SERVER_ERROR',
}

export const DAILY_PROCESS_STATUSES = {
  InProgress: 0,
  Failed: 1,
  Finished: 2
}

export const debounce = (callBack, timeout = 300) => {
  let timer

  return (...args) => {
    clearTimeout(timer)
    timer = setTimeout(() => {callBack.apply(this, args)}, timeout)
  }
}

export const returnDeliveryTypes = (t) => {
  return [
    {
      value: 1,
      label: t(`invoicePage.DELIVERY_METHOD_POST`)
    },
    {
      value: 2,
      label: t(`invoicePage.DELIVERY_METHOD_EMAIL`)
    },
    {
      value: 3,
      label: t(`invoicePage.DELIVERY_METHOD_PRINT`)
    }
  ]

}

export const returnInstallmentTypes = (t) => {
  return [
    {
      value: 1,
      label: t(`invoicePage.halfMonth`)
    },
    {
      value: 2,
      label: t(`invoicePage.month`)
    },
    {
      value: 3,
      label: t(`invoicePage.twoMonth`)
    }
  ]

}
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { apiGetSemesters, apiGetModifiedSemesters } from '../api/apiCommands'

export const getSemesterState = createAsyncThunk(
  'semester/getSemesterState',
  async (token) => {
    return apiGetSemesters(token)
  }
)

export const getSemesterModifedState = createAsyncThunk(
  'semester/getSemesterModifedState',
  async ({token, url}) => {
    return apiGetModifiedSemesters(token, url)
  }
)

const initialState = {
  totalSubjects: 0,
  items: [],
  currentSemester: null,
  status: null,
  error: null
}

const semesterSlice = createSlice({
  name: 'semester',

  initialState,
  
  reducers: {
    setNewStatus(state, action) {
      state.status = action.payload
    },

    nullifySemesters(state) {
      state.totalSubjects = initialState.totalSubjects
      state.items = initialState.items
      state.status = initialState.status
      state.error = initialState.error
    }
  },

  extraReducers: {
    [getSemesterState.pending]: (state, action) => {
      state.status = 'loading'
    },
    [getSemesterModifedState.pending]: (state, action) => {
      state.status = 'loading'
    },

    [getSemesterState.fulfilled]: (state, action) => {
      const response = action.payload

      if (response.fetchStatus) {
        state.error = 401
      } else {
        state.totalSubjects = response.totalItems
        state.items = response.items
        state.status = 'success'
        state.error = null
      }
    },

    [getSemesterModifedState.fulfilled]: (state, action) => {
      const response = action.payload

      if (response.fetchStatus) {
        state.error = 401
      } else {
        state.totalSubjects = response.totalItems
        state.items = response.items
        state.status = 'success'
        state.error = null
      }
    },

    [getSemesterState.rejected]: (state, action) => {
      state.status = 'failed'
      state.error = action.error.message
    },

    [getSemesterModifedState.rejected]: (state, action) => {
      state.status = 'failed'
      state.error = action.error.message
    },
  }
})

export default semesterSlice.reducer
export const { setNewStatus, nullifySemesters } = semesterSlice.actions
import React from 'react'
import styled from "styled-components"

const StylesStatisticBlock = styled.div.attrs(props => ({
  className: 'dashboard__statistics-item',
}))`
  background-color: ${props => props.color};
`

export const StatisticPanel = ({ statistics }) => {
  const statisticData = [
    {
      label: 'Absent Lessons',
      data: statistics.reduce((accumulator, currentValue) => accumulator + currentValue.totalAbsentLessons, 0),
      color: '#8ac4ff',
    },
    {
      label: 'Total Hours',
      data: statistics.reduce((accumulator, currentValue) => accumulator + currentValue.totalHours, 0),
      color: '#72CD75',
    }
  ]

  return (
    <div className="dashboard__statistics-container">
      {
        statisticData.map((item, index) =>
          <StylesStatisticBlock
            key={`statistic-block-${index}`}
            color={item.color}
          >
            <h3 className="dashboard__statistics-data">{item.label}</h3>
            <h3 className="dashboard__statistics-data">{item.data}</h3>
          </StylesStatisticBlock>
        )
      }
    </div>
  )

}

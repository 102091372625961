import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { apiGetInvoice } from '../api/apiCommands'

export const getInvoiceState = createAsyncThunk(
  'invoice/getInvoiceState',
  async ({token, url}) => {
    return apiGetInvoice(token, url)
  }
)

const initialState = {
  totalItems: 0,
  filteredItems: 0,
  items: [],
  status: null,
  error: null
}

const invoiceSlice = createSlice({
  name: 'invoice',

  initialState,
  
  reducers: {
    setNewStatus(state, action) {
      state.status = action.payload
    },

    nullifyInvoiceState(state) {
      state = Object.assign(state, initialState)
    }
  },

  extraReducers: {
    [getInvoiceState.pending]: (state, action) => {
      state.status = 'loading'
    },

    [getInvoiceState.fulfilled]: (state, action) => {
      const response = action.payload

      state.totalItems = response.totalItems
      state.filteredItems = response.filteredItems
      state.status = 'success'
      state.error = null
      state.items = response.items
    },

    [getInvoiceState.rejected]: (state, action) => {
      state.status = 'failed'
      state.error = action.error.message
    },
  }
})

export default invoiceSlice.reducer
export const { setNewStatus, nullifyInvoiceState } = invoiceSlice.actions

import React from 'react'
import classNames from 'classnames'

import AsideNavigation from './asideNavigation'

export const AsideNavigationComponent = ({
  className,
  pageType,
  overflowProperties,
  changeAsideStatus,
  asideIsOpen,
  activeNavigationProp,
  setActiveNavigationProp,
  asideNavigationGroups,
  asideLinksList
}) => {
  return (
    <aside
      className={classNames(
        "aside",
        {[className]: className},
        {"aside--open": asideIsOpen}
      )}
    >
      <AsideNavigation
        activeNavigationProp={activeNavigationProp}
        setActiveNavigationProp={setActiveNavigationProp}
        pageType={pageType}
        changeAsideStatus={changeAsideStatus}
        overflowProperties={overflowProperties}
        asideNavigationGroups={asideNavigationGroups}
        asideLinksList={asideLinksList}
      />
    </aside>
  )
}


import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { apiGetAttendanceList } from '../api/apiCommands'

export const getAttendanceState = createAsyncThunk(
  'attendanceStudents/getAttendanceState',
  async ({token, url}) => {
    return apiGetAttendanceList(token, url)
  }
)

export const getAttendanceReportState = createAsyncThunk(
  'attendanceStudents/getAttendanceReportState',
  async ({token, url, apiReportAction}) => {
    return apiReportAction(token, url)
  }
)

const initialState = {
  totalAttendanceStudents: 0,
  filteredItems: 0,
  items: [],
  students: [],
  report: null,
  status: null,
  error: null
}

const attendanceSlice = createSlice({
  name: 'attendanceStudents',

  initialState,

  reducers: {
    setNewStatus(state, action) {
      state.status = action.payload
    },

    nullifyAttendanceList(state, action) {
      state.items = []
    }
  },

  extraReducers: {
    [getAttendanceState.pending]: (state, action) => {
      state.status = 'loading'
    },

    [getAttendanceReportState.pending]: (state, action) => {
      state.status = 'loading'
    },

    [getAttendanceState.fulfilled]: (state, action) => {
      const response = action.payload

      state.filteredItems = response.filteredItems
      state.items = response.items
      state.students = response.students ? response.students : []
      state.status = 'success'
      state.error = null
    },

    [getAttendanceReportState.fulfilled]: (state, action) => {
      const response = action.payload
      state.report = response
    },

    [getAttendanceState.rejected]: (state, action) => {
      state.status = 'failed'
      state.error = action.error.message
    },

    [getAttendanceReportState.rejected]: (state, action) => {
      state.status = 'failed'
      state.error = action.error.message
    },
  }
})

export default attendanceSlice.reducer
export const { setNewStatus, nullifyAttendanceList } = attendanceSlice.actions
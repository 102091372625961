import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from "react-i18next"
import { CSSTransition } from 'react-transition-group'
import { Link } from 'react-router-dom'
import classNames from 'classnames'
import moment from 'moment'

import { DAILY_PROCESS_STATUSES } from '../../../utils/constants'
import { makeQueryParamsUrl, universalSortFunction } from '../../../utils/utils'
import { apiPostDailyEvents, apiPostAllDailyEvents } from '../../../api/apiCommands'
import { getDailyEventsState } from '../../../redux/dailyEventSlice'

import { ReactComponent as ReactNotificationchSvg } from '../../../assets/icon-notification.svg'
import { ReactComponent as ReactConfSvg } from '../../../assets/icon-lesson-conf.svg'
import { ReactComponent as ReactDeclineSvg } from '../../../assets/icon-reserved.svg'
import { ReactComponent as ReactProgressSvg } from '../../../assets/icon-progress.svg'
import { ReactComponent as ReactCloseSvg } from '../../../assets/icon-close.svg'


export function NotificationComponent({overflowProperties}) {
  const { t } = useTranslation()
  const sampleRef = useRef(null)
  const dispatch = useDispatch()
  const successToken = useSelector(state => state.userData.successToken)

  const notificationsList = useSelector(state => state.dailyEventsData.items)
  const [resetNotifications, setResetNotifications] = useState(true)

  const NOTIFICATION_STATUS_MAP = {
    0: <ReactProgressSvg />,
    1: <ReactDeclineSvg />,
    2: <ReactConfSvg />
  }

  const [sampleIsOpen, setSampleIsOpen] = useState(false)

  const escPressHandler = (evt) => {
    if (evt.keyCode === 27) {
      closeSample()
    }
  }

  const openSample = () => {
    setSampleIsOpen(!sampleIsOpen)

    document.addEventListener('keydown', escPressHandler)
    document.addEventListener('click', closeSampleHandler)
  }

  const closeSample = () => {
    setSampleIsOpen(false)
    
    document.removeEventListener('click', closeSampleHandler)
    document.removeEventListener('keydown', escPressHandler)
  }

  const closeSampleHandler = (evt) => {
    var path = evt.path || (evt.composedPath && evt.composedPath())
    if (path) {
      if (!path.includes(sampleRef.current)) {
        closeSample()
      }
    }
  }

  const markNotificationsAsReadHandler = (notification) => {
    if (notification) {
      apiPostDailyEvents(successToken, [notification]).then(() => {
        setResetNotifications(true)
      })
    } else {
      apiPostAllDailyEvents(successToken, "").then(() => {
        setResetNotifications(true)
      })
    }
  }

  useEffect(() => {
    if (resetNotifications) {
      dispatch(getDailyEventsState({
        token: successToken,
        url: makeQueryParamsUrl({
          IsRead: false,
          Statuses: [DAILY_PROCESS_STATUSES.Failed]
        })
      }))
    }
    setResetNotifications(false)
  }, [dispatch, successToken, resetNotifications])

  return (
    <div className="notification-container" ref={sampleRef}>
      <button
        className={classNames("notification-button",
          {"notification-button--active": sampleIsOpen}
        )}
        type="button" 
        aria-label="open notifications list"
        tabIndex={overflowProperties ? -1 : 0}
        onClick={() => openSample()}
      >
        <ReactNotificationchSvg />
        {notificationsList.length && <span>{notificationsList.length}</span>}
        Open notifications
      </button>

      <CSSTransition in={sampleIsOpen} timeout={250} classNames="popup-animation" unmountOnExit>
        <div className="notification__overpopup">
          {notificationsList.length > 0 && <div className="notification__overpopup-header">
            <h3>{t(`notifications.notifications`)}</h3>
            <button className="notification__read-button" onClick={() => markNotificationsAsReadHandler()}>
              {t(`notifications.markAllAsRead`)}
            </button>
          </div>}
          {notificationsList.length ?
            <ul className="notification__list">
              {[...notificationsList].sort((a, b) => universalSortFunction(b, a, "startDate")).map((notification, index) => {
                return (
                  <li className="notifications__content-card" key={index}>
                    <Link
                      to={`/admin/notifications/${notification.id}`}
                      className={classNames("notifications__link",
                      {"notifications__link--progress": notification.status === 0},
                      {"notifications__link--decline": notification.status === 1},
                      {"notifications__link--finished": notification.status === 2}
                      )}
                      onClick={() => closeSample()}
                      tabIndex={overflowProperties ? -1 : 0}
                    >
                      <span className={classNames("notifications__content-status")}>
                        {NOTIFICATION_STATUS_MAP[notification.status]}
                      </span>
                      <div>
                        <span className="notifications__content-data">
                          {moment(notification.startDate).format("D.M.YYYY")} {notification.finishDate && ('- ' + moment(notification.finishDate).format("D.M.YYYY"))}
                        </span>
                        <p className="notifications__content-description">
                          {t(`notifications.notificationType.${notification.type}`) + " " +
                          t(`notifications.responseKeys.${notification.notes}`)}
                        </p>
                      </div>
                    </Link>
                    <button
                        className="notifications__read-button popup__close-button" 
                        onClick={() => markNotificationsAsReadHandler(notification.id)}
                      >
                      <ReactCloseSvg />
                      Mark as read
                    </button>
                  </li>
                )
              })}
            </ul> :
            <div className="notification__empty">
              <span>{t(`notifications.empty`)}</span>
            </div>
          }
        </div>
      </CSSTransition>
    </div>
  )
}

import React from 'react'

import AsideContent from './asideContent'

import { ReactComponent as ReactBeritSvg } from '../../../assets/berit-logo.svg'
import { ReactComponent as ReactCloseSvg } from '../../../assets/icon-close.svg'

const AsideNavigation = ({
  setActiveNavigationProp,
  activeNavigationProp,
  pageType,
  changeAsideStatus,
  overflowProperties,
  asideNavigationGroups,
  asideLinksList
}) => {
  const linkCLickHandler = () => {
    changeAsideStatus(false)
  }

  return (
    <nav className='aside__navigation'>
      <ul className='aside__navigation-list'>
        <li className='aside__navigation-item'>
          <div className="aside__logo-container">
            <ReactBeritSvg className="aside__logo" />
            <span>Berit-Exbit</span>

            <button 
              className="aside__menu-button"
              onClick={linkCLickHandler}
            >
              <ReactCloseSvg />
              close
            </button>
          </div>
        </li>
        {
          Object.keys(asideNavigationGroups).map((group, index) => {
            return (
              <li className='aside__navigation-item' key={index}>
                <AsideContent
                  activeNavigationProp={activeNavigationProp}
                  setActiveNavigationProp={setActiveNavigationProp}
                  group={group}
                  pageType={pageType}
                  changeAsideStatus={changeAsideStatus}
                  overflowProperties={overflowProperties}
                  asideNavigationGroups={asideNavigationGroups}
                  asideLinksList={asideLinksList}
                />
              </li>
            )
          })
        }
      </ul>
    </nav>
  )
}

export default AsideNavigation
import React from 'react'

import { LogoutButton } from '../components/common/util-components/logoutButton'

export const StudentsPage = () => {
  return (
    <div>
      <h1>student page</h1>
      <LogoutButton />
    </div>
  )
}

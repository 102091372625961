import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { apiGetBookRooms } from '../api/apiCommands'

export const getBookRoomsState = createAsyncThunk(
  'bookRooms/getBookRoomsState',
  async ({token, url}) => {
    return apiGetBookRooms(token, url)
  }
)

const initialState = {
  totalBookRooms: 0,
    filteredItems: 0,
    items: [],
    status: null,
    error: null
}

const bookRoomSlice = createSlice({
  name: 'bookRooms',

  initialState,

  reducers: {
    setNewStatus(state, action) {
      state.status = action.payload
    },

    nullifyRooms(state) {
      state.totalBookRooms = initialState.totalBookRooms
      state.filteredItems = initialState.filteredItems
      state.items = initialState.items
      state.status = initialState.status
      state.error = initialState.error
    }
  },

  extraReducers: {
    [getBookRoomsState.pending]: (state, action) => {
        state.status = 'loading'
    },

    [getBookRoomsState.fulfilled]: (state, action) => {
      const response = action.payload

      state.totalBookRooms = response.totalBookRooms
      state.filteredItems = response.filteredItems
      state.items = response.items
      state.status = 'success'
      state.error = null
    },

    [getBookRoomsState.rejected]: (state, action) => {
      state.status = 'failed'
      state.error = action.error.message
    },
  }
})

export default bookRoomSlice.reducer
export const { setNewStatus, nullifyRooms } = bookRoomSlice.actions
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { apiGetSalaryData } from '../api/apiCommands'

export const getSalaryState = createAsyncThunk(
  'salary/getSalaryState',
  async (token) => {
    return apiGetSalaryData(token)
  }
)

const initialState = {
  items: [],

  totalTeachers: null,
  totalPlannedLessons: null,
  totalPlannedHours: null,
  totalExtraLessons: null,
  totalExtraHours: null,
  totalSalary: null,

  status: null,
  error: null
}

const salarySlice = createSlice({
  name: 'salary',

  initialState,
  
  reducers: {
    setNewStatus(state, action) {
      state.status = action.payload
    },

    nullifySalaryState(state) {
      state = Object.assign(state, initialState)
    }
  },

  extraReducers: {
    [getSalaryState.pending]: (state, action) => {
      state.status = 'loading'
    },

    [getSalaryState.fulfilled]: (state, action) => {
      const response = action.payload

      state = Object.assign(state, response)
      state.status = 'success'
      state.error = null
    },

    [getSalaryState.rejected]: (state, action) => {
      state.status = 'failed'
      state.error = action.error.message
    },
  }
})

export default salarySlice.reducer
export const { setNewStatus, nullifySalaryState } = salarySlice.actions
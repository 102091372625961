import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { apiGetProducts } from '../api/apiCommands'

export const getProductState = createAsyncThunk(
  'classes/getProductState',
  async ({token, url}) => {
    return apiGetProducts(token, url)
  }
)

const initialState = {
  totalItems: 0,
  filteredItems: 0,
  items: [],
  status: null,
  error: null
}

const productSlice = createSlice({
  name: 'classes',

  initialState,
  
  reducers: {
    setNewStatus(state, action) {
      state.status = action.payload
    },

    nullifyProductsState(state) {
      state = Object.assign(state, initialState)
    }
  },

  extraReducers: {
    [getProductState.pending]: (state, action) => {
      state.status = 'loading'
    },

    [getProductState.fulfilled]: (state, action) => {
      const response = action.payload

      state.totalItems = response.totalItems
      state.filteredItems = response.filteredItems
      state.status = 'success'
      state.error = null
      state.items = response.items
    },

    [getProductState.rejected]: (state, action) => {
      state.status = 'failed'
      state.error = action.error.message
    },
  }
})

export default productSlice.reducer
export const { setNewStatus, nullifyProductsState } = productSlice.actions

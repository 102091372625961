import { combineReducers } from 'redux'

import userSlice  from './userSlice'
import roomSlice from './roomSlice'
import subjectSlice from './subjectSlice'
import semesterSlice from './semesterSlice'
import selectorSlice from './selectorSlice'
import studentSlice from './studentSlice'
import classSlice from './classSlice'
import classGroupSlice from './classGroupSlice'
import teacherSlice from './teacherSlice'
import holidaysSlice from './holidaysSlice'
import lessonsSlice from './lessonsSlice'
import bookRoomSlice from './bookRoomSlice'
import dailyLessonsSlice from './dailyLessonsSlice'
import profileSlice from "./profileSlice"
import attendanceSlice from './attendanceSlice'
import teacherSalarySlice from './teacherSalarySlice'
import futherLessonsSlice from './furtherLessonsSlice'
import dailyEventsSlice from './dailyEventSlice'
import gradesSlice from './gradesSlice'
import competenceCardsSlice from './competenceCardsSlice'
import competenceTemplatesSlice from './competenceTemplateSlice'
import tagsSlice from './tagsSlice'
import proofOfCompetenceSlice from './proofSlice'
import notificationsSlice from './notificationSlice'
import salarySlice from './salarySlice'
import modulesSlice from './moduleSlice'
import additionalHourSlice from './additionalHourSlice'
import productSlice from './productSlice'
import invoiceTemplateSlice from './invoiceTemplateSlice'
import invoiceSlice from './invoiceSlice'

const rootReducer = combineReducers({
  userData: userSlice,
  roomData: roomSlice,
  subjectData: subjectSlice,
  semesterData: semesterSlice,
  selectorData: selectorSlice,
  studentsData: studentSlice,
  classData: classSlice,
  classGroupsData: classGroupSlice,
  teachersData: teacherSlice,
  holidaysData: holidaysSlice,
  lessonsData: lessonsSlice,
  furtherLessonsData: futherLessonsSlice,
  dailyLessonsData: dailyLessonsSlice,
  bookRoomData: bookRoomSlice,
  profileData: profileSlice,
  attendanceData: attendanceSlice,
  teacherSalaryData: teacherSalarySlice,
  dailyEventsData: dailyEventsSlice,
  gradesData: gradesSlice,
  competenceCardsData: competenceCardsSlice,
  competenceTemplatesData: competenceTemplatesSlice,
  tagsData: tagsSlice,
  proofOfCompetenceData: proofOfCompetenceSlice,
  notificationsData: notificationsSlice,
  salaryData: salarySlice,
  modulesData: modulesSlice,
  additionalHoursData: additionalHourSlice,
  productData: productSlice,
  invoiceTemplateData: invoiceTemplateSlice,
  invoiceData: invoiceSlice
})

export default rootReducer;

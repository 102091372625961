import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { apiGetProofsOfCompetence } from '../api/apiCommands'

export const getProofOfCompetenceState = createAsyncThunk(
  'attendanceStudents/getProofOfCompetenceState',
  async ({token, url}) => {
    return apiGetProofsOfCompetence(token, url)
  }
)

const initialState = {
  totalItems: 0,
  filteredItems: 0,
  items: [],
  status: null,
  error: null
}

const proofOfCompetenceSlice = createSlice({
  name: 'attendanceStudents',

  initialState,

  reducers: {
    setNewStatus(state, action) {
      state.status = action.payload
    },

    nullifyProofs(state) {
      state.totalItems = initialState.totalItems
      state.filteredItems = initialState.filteredItems
      state.items = initialState.items
      state.status = initialState.status
      state.error = initialState.error
    }
  },

  extraReducers: {
    [getProofOfCompetenceState.pending]: (state, action) => {
      state.status = 'loading'
    },

    [getProofOfCompetenceState.fulfilled]: (state, action) => {
      const response = action.payload

      state.items = response.items
      state.totalItems = response.totalItems
      state.filteredItems = response.filteredItems
      state.status = 'success'
      state.error = null
    },

    [getProofOfCompetenceState.rejected]: (state, action) => {
      state.status = 'failed'
      state.error = action.error.message
    },
  }
})

export default proofOfCompetenceSlice.reducer
export const { setNewStatus, nullifyProofs } = proofOfCompetenceSlice.actions
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { apiGetFurtherLessons } from "../api/apiCommands"

export const getFurtherLessonsState = createAsyncThunk(
  'futherLessons/getFurtherLessonsState',
  async ({token, url}) => {
    return apiGetFurtherLessons(token, url)
  }
)

const initialState = {
  totalItems: 0,
  filteredItems: 0,
  items: [],
  status: null,
  error: null
}

const futherLessonsSlice = createSlice({
  name: 'futherLessons',

  initialState,

  reducers: {
      setNewStatus(state, action) {
        state.status = action.payload
      },

      nullifyLessons(state) {
        state.totalItems = initialState.totalItems
        state.filteredItems = initialState.filteredItems
        state.items = initialState.items
        state.status = initialState.status
        state.error = initialState.error
      }
    },

    extraReducers: {
      [getFurtherLessonsState.pending]: (state) => {
        state.status = 'loading'
      },

      [getFurtherLessonsState.fulfilled]: (state, action) => {
        const response = action.payload

        state.totalItems = response.totalItems
        state.filteredItems = response.filteredItems
        state.status = 'success'
        state.error = null
        state.items = response.items
      },

      [getFurtherLessonsState.rejected]: (state, action) => {
        state.status = 'failed'
        state.error = action.error.message
      },
    }
})

export default futherLessonsSlice.reducer
export const { setNewStatus, nullifyRooms } = futherLessonsSlice.actions
import React, {useState} from 'react'
import {ReactComponent as ReactPathSvg} from "../../../assets/icon-path.svg"
import moment from "moment"
import DatePicker from "react-datepicker"
import {ReactComponent as ReactCalendarSvg} from "../../../assets/icon-tametable.svg"
import classnames from "classnames"

export const LineCalendar = ({ chosenDay , setChosenDay }) => {
  const [weekCoff, setWeekCoff] = useState(0)

  const changeWeekCoffHandler = (bool) => {
    if (bool) {
      setWeekCoff(weekCoff + 1)
    } else {
      setWeekCoff(weekCoff - 1)
    }
  }

  return (
    <div className="dashboard__horizontal-calendar">
      <div className="dashboard__switch-container">
        <button
          className="dashboard__horizontal-switch"
          onClick={() => { changeWeekCoffHandler(false) }}
          // tabIndex={overflowProperties ? -1 : 0}
        >
          <ReactPathSvg />
          Previous week
        </button>
        <div className="dashboard__horizontal-datepicker">
          <label htmlFor="horizontalDatepicker">{moment(new Date(chosenDay)).format("D MMM, YYYY")}</label>
          <DatePicker
            name="horizontalDatepicker"
            id="horizontalDatepicker"
            selected={chosenDay ? new Date(chosenDay) : new Date()}
            onChange={date => {
              setChosenDay(moment(date))
              setWeekCoff(Math.round(moment(date).diff(moment(), 'week', true)))
            }}
            autoComplete="off"
            minDate={new Date()}
            dateFormat="MMMM d, yyyy"
            // tabIndex={overflowProperties ? -1 : 0}
          />
          <ReactCalendarSvg />
        </div>
        <button
          className="dashboard__horizontal-switch"
          onClick={() => changeWeekCoffHandler(true)}
          // tabIndex={overflowProperties ? -1 : 0}
        >
          <ReactPathSvg />
          Next week
        </button>
      </div>

      <ul className="dashboard__horizontal-list">
        {[...Array(7)].map((day, index) => {
          const currentDay = moment().day(index + (1 + 7 * weekCoff))

          return (
            <li
              className={classnames(
                "dashboard__horizontal-item",
                {"dashboard__horizontal-item--active": Math.round(moment(new Date(chosenDay)).diff(currentDay, 'days', true)) === 0}
              )}
              key={index}
              onClick={() => {
                setChosenDay(currentDay)
              }}
            >
              <span className="dashboard__horizontal-day">{currentDay.format("ddd")}</span>
              <span className="dashboard__horizontal-number">{currentDay.format("D")}</span>
            </li>
          )
        })}
      </ul>
    </div>
  )
}

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { apiGetClasses } from '../api/apiCommands'

export const getClassState = createAsyncThunk(
  'classes/getClassState',
  async ({token, url}) => {
    return apiGetClasses(token, url)
  }
)

const initialState = {
  totalClasses: 0,
  items: [],
  status: null,
  error: null
}

const classSlice = createSlice({
  name: 'classes',

  initialState,
  
  reducers: {
    setNewStatus(state, action) {
      state.status = action.payload
    },

    nullifyRooms(state) {
      state.totalClasses = initialState.totalClasses
      state.items = initialState.items
      state.status = initialState.status
      state.error = initialState.error
    }
  },

  extraReducers: {
    [getClassState.pending]: (state, action) => {
      state.status = 'loading'
    },

    [getClassState.fulfilled]: (state, action) => {
      const response = action.payload

      state.totalClasses = response.totalItems
      state.status = 'success'
      state.error = null
      state.items = response.items
    },

    [getClassState.rejected]: (state, action) => {
      state.status = 'failed'
      state.error = action.error.message
    },
  }
})

export default classSlice.reducer
export const { setNewStatus, nullifyRooms } = classSlice.actions

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { apiGetTags } from '../api/apiCommands'

export const getTagsState = createAsyncThunk(
  'tags/getTagsState',
  async ({token, url}) => {
    return apiGetTags(token, url)
  }
)

const initialState = {
  totalTags: 0,
  items: [],
  status: null,
  error: null
}

const tagsSlice = createSlice({
  name: 'tags',

  initialState,
  
  reducers: {
    setNewStatus(state, action) {
      state.status = action.payload
    },

    nullifyTags(state) {
      state.totalTags = initialState.totalTags
      state.items = initialState.items
      state.status = initialState.status
      state.error = initialState.error
    }
  },

  extraReducers: {
    [getTagsState.pending]: (state, action) => {
      state.status = 'loading'
    },

    [getTagsState.fulfilled]: (state, action) => {
      const response = action.payload

      state.totalTags = response.totalItems
      state.status = 'success'
      state.error = null
      state.items = response.items
    },

    [getTagsState.rejected]: (state, action) => {
      state.status = 'failed'
      state.error = action.error.message
    },
  }
})

export default tagsSlice.reducer
export const { setNewStatus, nullifyTags } = tagsSlice.actions
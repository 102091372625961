import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { apiGetNotificationsList } from '../api/apiCommands'

export const getNotificationsState = createAsyncThunk(
  'notifications/getNotificationsState',
  async ({token, url}) => {
    return apiGetNotificationsList(token, url)
  }
)

const initialState = {
  totalItems: 0,
  filteredItems: 0,
  items: [],
  status: null,
  error: null
}

const notificationsSlice = createSlice({
  name: 'notifications',

  initialState,
  
  reducers: {
    setNewStatus(state, action) {
      state.status = action.payload
    }
  },

  extraReducers: {
    [getNotificationsState.pending]: (state, action) => {
      state.status = 'loading'
    },

    [getNotificationsState.fulfilled]: (state, action) => {
      const response = action.payload

      state.totalItems = response.totalItems
      state.filteredItems = response.filteredItems
      state.items = response.items
      state.status = 'success'
      state.error = null
    },

    [getNotificationsState.rejected]: (state, action) => {
      state.status = 'failed'
      state.error = action.error.message
    },
  }
})

export default notificationsSlice.reducer
export const { setNewStatus } = notificationsSlice.actions
import React, { Fragment } from 'react'
import { useTranslation } from "react-i18next"

import { ReactComponent as ReactVoidSvg } from '../../assets/icon-void.svg'

export const VoidComponent = ({error = ""}) => {
  const { t } = useTranslation()

  // if (error && error?.toLowerCase() === "Cannot Read Property 'Status' Of Undefined".toLowerCase()) {
  //   error = "Server connection problem. Please make sure the security certificate is accepted"
  // }

  return (
    <div className="void">
      <ReactVoidSvg />
      {error ? 
        <Fragment>
          {/* <span>{t(`void.EMPTY_DATA`)}</span> */}
          <span style={{textAlign:'center'}}>{error}</span>
        </Fragment>
        : 
        <span>{t(`void.EMPTY_DATA`)}</span>
      }
    </div>
  )
}
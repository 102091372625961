import React, { Fragment } from 'react'
import { useTranslation } from "react-i18next"

export function LoadingComponent() {
  const { t } = useTranslation()

  return (
    <Fragment>
      <div className="loading-container">
        <span>{t(`loading`)}</span>
        <div className="loading__circle">
          <div className="loading__spinner">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
      </div>

    </Fragment>
  )
}

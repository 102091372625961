import {
  apiGetTemplate,
  apiPostTemplate,
  apiChangeTemplate,
  apiDeleteTemplate,
  apiChangeSomethingTemplate,
  apiGetTemplateByType,
  apiGetCurrentTemplate,
  apiGetByFewRoutsTemplate,
  apiGetTemplateModifed,
  apiPostTemplateConfirm,
  apiPostTemplateTwoRouts,
  apiDownloadSomethingTemplate,
  apiPostFilesTemplate,
  apiPostNullTemplate
} from './apiTemplates'

// ------------ Subject requests ------------ \\

export const apiGetSubjects = (token, url) => apiGetTemplateModifed(token, 'subject', url)
export const apiPostSubject = (token, newSubject) => apiPostTemplate(token, 'subject', newSubject)
export const apiChangeSubject = (token, changedSubject) => apiChangeTemplate(token, 'subject', changedSubject)
export const apiDeleteSubject = (token, deletedId) => apiDeleteTemplate(token, 'subject', deletedId)

// special API actions for room: "archive", "unarchive"

export const apiArchiveSubject = (token, subjectId) => apiChangeSomethingTemplate(token, 'subject', 'archive', subjectId)
export const apiUnarchiveSubject = (token, subjectId) => apiChangeSomethingTemplate(token, 'subject', 'unarchive', subjectId)

// ------------ Lessons / Lessontemplate requests ---------- \\

export const apiGetLessonsByFilters = (token, url) => apiGetTemplateModifed(token, 'lessontemplate', url)
export const apiDeleteLesson = (token, deletedId) => apiDeleteTemplate(token, 'lessontemplate', deletedId)
export const apiPostPlanLesson = (token, newPlanLesson) => apiPostTemplate(token, 'lessontemplate', newPlanLesson)
export const apiChangeLesson = (token, editedPlanLesson) => apiChangeTemplate(token, 'lessontemplate', editedPlanLesson)
export const apiDeclineLesson = (token, lessonId) => apiChangeSomethingTemplate(token, 'lesson', 'decline', lessonId)
export const apiConfirmPlanLesson = (token, semesterId) => apiPostTemplateConfirm(token, 'semester', 'confirm/background', 'semesterId', semesterId)

// ------------ FurtherLessons requests ---------- \\

export const apiGetFurtherLessons = (token, url) => apiGetTemplateModifed(token, 'lesson', url)
export const apiPostFurtherLesson = (token, newFurtherLesson) => apiPostTemplate(token, 'furtherlesson', newFurtherLesson)
export const apiChangeFurtherLesson = (token, editedFurtherLesson) => apiChangeTemplate(token, 'furtherlesson', editedFurtherLesson)
export const apiDeleteFurtherLesson = (token, deletedId) => apiDeleteTemplate(token, 'furtherlesson', deletedId)

// ------------ dailyLessons requests ---------- \\

export const apiGetDailyLessons = (token, url) => apiGetTemplateModifed(token, 'lesson', url)
export const apiGetDailyLessonHours = (token, date) => apiGetByFewRoutsTemplate(token, 'lesson', 'getstarttimesbydate', date)
export const apiPostDailyLessons = (token, newDailyLesson) => apiPostTemplate(token, 'lesson', newDailyLesson)
export const apiDeclineDailyLessons = (token, declineReason) => apiPostTemplate(token, 'lesson/decline', declineReason)
export const apiUndoDailyLessons = (token, undoReason) => apiPostTemplate(token, 'lesson/decline/undo', undoReason)
export const apiFinishDailyLesson = (token, dailyLessonId) => apiChangeSomethingTemplate(token, 'lesson', 'finish', dailyLessonId)
export const apiGetLessonStatistics = (token, params) => apiGetTemplateModifed(token, 'lesson/statistics', params)

// ------------ Attendance requests ---------- \\

export const apiGetAttendanceList = (token, url) => apiGetTemplateModifed(token, 'attendance', url)
export const apiChangeAttendance = (token, changedAttendance) => apiChangeTemplate(token, 'attendance', changedAttendance)
export const apiGetCurrentLessonAttendance = ({token, url, lessonId}) => apiGetTemplateModifed(token, `attendance/${lessonId}`, url)
export const apiGetAttendanceClassReport = (token, url) => apiGetTemplateModifed(token, 'attendance/class/report', url)
export const apiGetAttendanceStudentReport = (token, url) => apiGetTemplateModifed(token, 'attendance/student/report', url)
export const apiGetAttendanceModuleReport = (token, url) => apiGetTemplateModifed(token, 'attendance/module/report', url)

// ------------ Room requests ------------ \\

export const apiGetRooms = (token) => apiGetTemplate(token, 'room')
export const apiPostRoom = (token, newRoom) => apiPostTemplate(token, 'room', newRoom)
export const apiChangeRoom = (token, changedRoom) => apiChangeTemplate(token, 'room', changedRoom)
export const apiDeleteRoom = (token, deletedId) => apiDeleteTemplate(token, 'room', deletedId)

// special API actions for room: "makeunavailable", "makeavailable", "archive", "unarchive"

export const apiMakeUnavailableRoom = (token, roomId) => apiChangeSomethingTemplate(token, 'room', 'makeunavailable', roomId)
export const apiMakeAvailableRoom = (token, roomId) => apiChangeSomethingTemplate(token, 'room', 'makeavailable', roomId)
export const apiArchiveRoom = (token, roomId) => apiChangeSomethingTemplate(token, 'room', 'archive', roomId)
export const apiUnarchiveRoom = (token, roomId) => apiChangeSomethingTemplate(token, 'room', 'unarchive', roomId)

// ------------ Semester requests ------------ \\

export const apiGetSemesters = (token) => apiGetTemplate(token, 'semester')
export const apiGetCurrentSemester = (token, semestertId) => apiGetCurrentTemplate(token, 'semester', semestertId)
export const apiGetModifiedSemesters = (token, url) => apiGetTemplateModifed(token, 'semester', url)
export const apiPostSemester = (token, newSubject) => apiPostTemplate(token, 'semester', newSubject)
export const apiCopySemester = (token, newSubject) => apiPostTemplateTwoRouts(token, 'semester', 'copy', newSubject)
export const apiChangeSemester = (token, changedSubject) => apiChangeTemplate(token, 'semester', changedSubject)
export const apiDeleteSemester = (token, deletedId) => apiDeleteTemplate(token, 'semester', deletedId)
export const apiArchiveSemester = (token, roomId) => apiChangeSomethingTemplate(token, 'semester', 'archive', roomId)
export const apiUnarchiveSemester = (token, roomId) => apiChangeSomethingTemplate(token, 'semester', 'unarchive', roomId)
export const apiPostSemesterGenerator = (token, semesterId) => apiPostTemplateConfirm(token, 'semester', 'generate', 'semesterId', semesterId)

// ------------ Students requests ------------ \\

export const apiGetCurrentStudent = (token, studentId) => apiGetCurrentTemplate(token, 'student', studentId)
export const apiDownloadStudentsList = ({token, route = "student/download", url, setPercentage, setProgress, fileName = "studentsList", fileType}) => 
  apiDownloadSomethingTemplate({token, route, url, setPercentage, setProgress, fileName, fileType})
export const apiGetModifiedStudents = (token, url) => apiGetTemplateModifed(token, 'student', url)
export const apiGetByClassGroups = (token, classId) => apiGetByFewRoutsTemplate(token, 'student', 'getbyclassgroups', classId)
export const apiPostStudent = (token, newStudent) => apiPostTemplate(token, 'student', newStudent)
export const apiPostStudentsExcel = (token, files) => apiPostFilesTemplate(token, 'student/upload', files)
export const apiChangeStudent = (token, changedStudent) => apiChangeTemplate(token, 'student', changedStudent)
export const apiDeleteStudent = (token, deletedId) => apiDeleteTemplate(token, 'student', deletedId)

// special API actions for student: "archive", "unarchive"

export const apiArchiveStudent = (token, studentId) => apiChangeSomethingTemplate(token, 'student', 'archive', studentId)
export const apiUnarchiveStudent = (token, studentId) => apiChangeSomethingTemplate(token, 'student', 'unarchive', studentId)

// ------------ Class requests ------------ \\

export const apiGetClasses = (token, url) => apiGetTemplateModifed(token, 'class', url)
export const apiGetCurrentClass = (token, classId) => apiGetCurrentTemplate(token, 'class', classId)
export const apiPostClasses = (token, newClass) => apiPostTemplate(token, 'class', newClass)
export const apiChangeClasses = (token, changedClass) => apiChangeTemplate(token, 'class', changedClass)
export const apiDeleteClasses = (token, deletedId) => apiDeleteTemplate(token, 'class', deletedId)

// ------------ ClassGroup requests ------------ \\

export const apiGetClassGroups = (token, url) => apiGetTemplateModifed(token, 'classgroup', url)
export const apiPostClassGroups = (token, newClassGroup) => apiPostTemplate(token, 'classgroup', newClassGroup)
export const apiChangeClassGroups = (token, changedClassGroup) => apiChangeTemplate(token, 'classgroup', changedClassGroup)
export const apiDeleteClassGroup = (token, objectId) => apiDeleteTemplate(token, 'classgroup', objectId)

// ------------ Profile requests ------------ \\

export const apiGetProfile = (token) => apiGetTemplateModifed(token, 'profile')
export const apiUpgradeProfile = (token, newObject) => apiChangeTemplate(token, 'profile', newObject)

// ------------ Teachers requests ------------ \\

export const apiGetTeachers = (token, url) => apiGetTemplateModifed(token, 'teacher', url)
export const apiGetCurrentTeacher = (token, teacherId) => apiGetCurrentTemplate(token, 'teacher', teacherId)
export const apiPostTeacher = (token, newTeacher) => apiPostTemplate(token, 'teacher', newTeacher)
export const apiChangeTeacher = (token, changedTeacher) => apiChangeTemplate(token, 'teacher', changedTeacher)
export const apiDeleteTeacher = (token, deletedId) => apiDeleteTemplate(token, 'teacher', deletedId)

// special API actions for student: "archive", "unarchive"

export const apiArchiveTeacher = (token, teacherId) => apiChangeSomethingTemplate(token, 'teacher', 'archive', teacherId)
export const apiUnarchiveTeacher = (token, teacherId) => apiChangeSomethingTemplate(token, 'teacher', 'unarchive', teacherId)

// ------------ Teacher Salary requests ------------ \\

export const apiGetAllTeacherDailyLessons = (token) => apiGetTemplate(token, 'teachersalary/getallteacherhours')
export const apiGetAllTeacherHours = (token) => apiGetTemplate(token, 'teachersalary/consolidateallteacherhours')

// ------------ Holidays requests ------------ \\

export const apiGetHolidays = (token) => apiGetTemplate(token, 'holiday')
export const apiGetSpecialHolidays = (token, url) => apiGetTemplateModifed(token, 'holiday', url)
export const apiGetCurrentHoliday = (token, holidayId) => apiGetCurrentTemplate(token, 'holiday', holidayId)
export const apiPostHoliday = (token, newHoliday) => apiPostTemplate(token, 'holiday', newHoliday)
export const apiChangeHoliday = (token, changedHoliday) => apiChangeTemplate(token, 'holiday', changedHoliday)
export const apiDeleteHoliday = (token, deletedId) => apiDeleteTemplate(token, 'holiday', deletedId)

// ------------ BookRoom requests ------------ \\

export const apiGetBookRooms = (token, url, body) => apiGetTemplateModifed(token, 'booking', url, body)
export const apiPostBookRooms = (token, bookRooms) => apiPostTemplate(token, 'booking/multiple', bookRooms)
export const apiDeleteBookRoom = (token, deletedId) => apiDeleteTemplate(token, 'booking', deletedId)

// ------------ DailyProcess requests ------------ \\

export const apiGetDailyEvents = (token, url) => apiGetTemplateModifed(token, 'dailyprocess', url)
export const apiGetCurrentDailyEvent = (token, dailyId) => apiGetCurrentTemplate(token, 'dailyprocess', dailyId)
export const apiPostAllDailyEvents = (token, dailyDays) => apiPostNullTemplate(token, 'dailyprocess/read', dailyDays)
export const apiPostDailyEvents = (token, dailyDays) => apiPostTemplate(token, 'dailyprocess/read', dailyDays)

// ------------ Grades requests ------------ \\

export const apiGetGrades = (token, url) => apiGetTemplateModifed(token, 'grade', url)
export const apiGetCurrenGrade = (token, gradeId) => apiGetCurrentTemplate(token, 'grade', gradeId)
export const apiChangeGrade = (token, gradeId) => apiChangeTemplate(token, 'grade', gradeId)
export const apiGetStudentAverageGrades = (token, url) => apiGetTemplateModifed(token, 'grade/average', url)
export const apiGetStudentDetailedGrades = (token, url) => apiGetTemplateModifed(token, 'grade/details', url)

// ------------ Exam requests ------------ \\

export const apiPostCreateExam = (token, examData) => apiPostTemplate(token, 'exam', examData)
export const apiGetExamDetails = (token, examId) => apiGetCurrentTemplate(token, 'exam', examId)
export const apiChangeExamDetails = (token, examData) => apiChangeTemplate(token, 'exam', examData)
export const apiDeleteExam = (token, deletedId) => apiDeleteTemplate(token, 'exam', deletedId)

// ------------ CompetenceCard requests ------------ \\

export const apiPostCompetenceCard = (token, cardData) => apiPostTemplate(token, 'competencecard', cardData)
export const apiGetCompetenceCards = (token, url) => apiGetTemplateModifed(token, 'competencecard', url)
export const apiGetCompetenceCard = (token, cardId) => apiGetCurrentTemplate(token, 'competencecard', cardId)
export const apiChangeCompetenceCard = (token, cardData) => apiChangeTemplate(token, 'competencecard', cardData)
export const apiDeleteCompetenceCard = (token, deletedId) => apiDeleteTemplate(token, 'competencecard', deletedId)

// ------------ CompetenceTemplate requests ------------ \\

export const apiGetCompetenceTemplate = (token, templateId) => apiGetCurrentTemplate(token, 'competencetemplate', templateId)
export const apiGetCompetenceTemplates = (token, url) => apiGetTemplateModifed(token, 'competencetemplate', url)
export const apiPostCompetenceTemplate = (token, templateData) => apiPostTemplate(token, 'competencetemplate', templateData)
export const apiChangeCompetenceTemplate = (token, templateData) => apiChangeTemplate(token, 'competencetemplate', templateData)
export const apiDeleteCompetenceTemplate = (token, deletedId) => apiDeleteTemplate(token, 'competencetemplate', deletedId)

// ------------ Tags requests ------------ \\

export const apiGetTags = (token, url) => apiGetTemplateModifed(token, 'tag', url)

// ------------ Settings requests (special selections for inputs) ------------ \\
// ----- type is a discrete value (1 - countries, 2 - studentTypes, 
// ----- 4 - lessonTypes, 8 - salutations, 16 - declineReasons, 32 - nationalities
// ----- 64 - attendanceTypes, 128 - classes, 256 - rooms, 512 - teachers, 1024 - semesters, 2048 - subjects) or their sum

export const apiGetSelectOptions = (token, type) => apiGetTemplateByType(token, 'settings', type)
export const apiGetSelectStydentTypes = (token, url) => apiGetTemplateModifed(token, 'lesson/geteducationtypes', url)

// ------------ Proof of Competence requests ------------ \\

export const apiGetProofsOfCompetence = (token, url) => apiGetTemplateModifed(token, 'proofofcompetence', url)
export const apiGetProofOfCompetence = (token, proofId) => apiGetCurrentTemplate(token, 'proofofcompetence', proofId)
export const apiPostProofOfCompetence = (token, proofData) => apiPostTemplate(token, 'proofofcompetence', proofData)
export const apiDeleteProofOfCompetence = (token, proofId) => apiDeleteTemplate(token, 'proofofcompetence', proofId)

export const apiTeacherUpdateProofOfCompetence = (token, proofData) => apiChangeTemplate(token, 'proofofcompetence/teacher/update', proofData)
export const apiStudentUpdateProofOfCompetence = (token, proofData) => apiChangeTemplate(token, 'proofofcompetence/student/update', proofData)
export const apiTeacherPostLinkToFill = (token, objectId) => apiChangeSomethingTemplate(token, 'proofofcompetence/teacher', 'sendlinktofill', objectId)
export const apiTeacherPostLinkToApprove = (token, objectId) => apiChangeSomethingTemplate(token, 'proofofcompetence/teacher', 'sendlinktoapprove', objectId)
export const apiTeacherPostApprove = (token, approveId) => apiChangeSomethingTemplate(token, 'proofofcompetence/teacher', 'approve', approveId)
export const apiStudentPostApprove = (token, approveId) => apiChangeSomethingTemplate(token, 'proofofcompetence/student', 'approve', approveId)

// ------------ Notification requests ------------ \\

export const apiGetNotificationsList = (token, url) => apiGetTemplateModifed(token, 'notification', url)
export const apiGetNotification = (token, proofId) => apiGetCurrentTemplate(token, 'notification', proofId)

// ------------ Salary requests ------------ \\

export const apiGetSalaryData = ({token, url}) => apiGetTemplateModifed(token, 'salary', url)

// ------------ Modules requests ------------ \\

export const apiGetModulesList = (token, url) => apiGetTemplateModifed(token, 'module', url)
export const apiChangeModule = (token, moduleData) => apiChangeTemplate(token, 'module', moduleData)
export const apiPostModule = (token, moduleData) => apiPostTemplate(token, 'module', moduleData)
export const apiGetCurrentModule = (token, moduleId) => apiGetCurrentTemplate(token, 'module', moduleId)
export const apiDeleteModule = (token, moduleId) => apiDeleteTemplate(token, 'module', moduleId)

// ------------ Additional-hours requests ------------ \\

export const apiGetAdditionalHours = (token, url) => apiGetTemplateModifed(token, 'additionalhour', url)
export const apiPostAdditionalHour = (token, moduleData) => apiPostTemplate(token, 'additionalhour', moduleData)
export const apiConfirmAdditionalHour = (token, moduleData) => apiPostTemplate(token, 'additionalhour/confirm', moduleData)
export const apiChangeAdditionalHour = (token, moduleData) => apiChangeTemplate(token, 'additionalhour', moduleData)
export const apiGetCurrentAdditionalHour = (token, hourId) => apiGetCurrentTemplate(token, 'additionalhour', hourId)
export const apiDeleteAdditionalHour = (token, hourId) => apiDeleteTemplate(token, 'additionalhour', hourId)

// ------------ SalaryPeriod requests ------------ \\

export const apiLockSalaryPeriod = (token, periodData) => apiPostTemplate(token, 'salaryperiod/lock', periodData)

// ------------ Products requests ------------ \\

export const apiGetProducts = (token, url) => apiGetTemplateModifed(token, 'product', url)
export const apiChangeProduct = (token, moduleData) => apiChangeTemplate(token, 'product', moduleData)
export const apiPostProduct = (token, moduleData) => apiPostTemplate(token, 'product', moduleData)
export const apiGetCurrentProduct = (token, moduleId) => apiGetCurrentTemplate(token, 'product', moduleId)
export const apiDeleteProduct = (token, moduleId) => apiDeleteTemplate(token, 'product', moduleId)

// ------------ InvoiceTemplate requests ------------ \\

export const apiGetInvoiceTemplates = (token, url) => apiGetTemplateModifed(token, 'invoicetemplate', url)
export const apiChangeInvoiceTemplate = (token, moduleData) => apiChangeTemplate(token, 'invoicetemplate', moduleData)
export const apiPostInvoiceTemplate = (token, moduleData) => apiPostTemplate(token, 'invoicetemplate', moduleData)
export const apiGetCurrentInvoiceTemplate = (token, moduleId) => apiGetCurrentTemplate(token, 'invoicetemplate', moduleId)
export const apiDeleteInvoiceTemplate = (token, moduleId) => apiDeleteTemplate(token, 'invoicetemplate', moduleId)

// ------------ Invoice requests ------------ \\

export const apiGetInvoice = (token, url) => apiGetTemplateModifed(token, 'invoice', url)
export const apiPostInvoice = (token, invoiceData) => apiPostTemplate(token, 'invoice', invoiceData)
export const apiResendInvoice = (token, invoiceData, invoiceId) => apiPostTemplate(token, `invoice/resend/${invoiceId}`, invoiceData)
export const apiPostInvoiceExcel = (token, files) => apiPostFilesTemplate(token, 'invoice/upload', files)
export const apiPutInstallment = (token, installment) => apiChangeTemplate(token, 'invoice/installment', installment)
export const apiDeleteInvoice = (token, invoiceId) => apiDeleteTemplate(token, 'invoice', invoiceId)

// ------------ Download (animated) requests ------------ \\

export const apiDownloadTimetablePdf = ({
  token,
  route = "lessontemplate/pdf",
  url,
  setPercentage,
  setProgress,
  catchFunction,
  fileName = "timetable",
  fileType = 'pdf'
}) => apiDownloadSomethingTemplate({token, route, url, setPercentage, setProgress, fileName, fileType, catchFunction})

export const apiDownloadTimetableDock = ({
  token,
  route = "lessontemplate/word",
  url,
  setPercentage,
  setProgress,
  catchFunction,
  fileName = "timetable",
  fileType="DOCX"
}) => apiDownloadSomethingTemplate({token, route, url, setPercentage, setProgress, fileName, fileType, catchFunction})

export const apiDownloadStudentsExcel = ({
  token,
  route = "student/download-full",
  url,
  setPercentage,
  setProgress,
  catchFunction,
  fileName = "Students",
  fileType="xlsx"
}) => apiDownloadSomethingTemplate({token, route, url, setPercentage, setProgress, fileName, fileType, catchFunction})

export const apiDownloadStudentsShortExcel = ({
  token,
  route = "student/download-short",
  url,
  setPercentage,
  setProgress,
  catchFunction,
  fileName = "Students",
  fileType="xlsx"
}) => apiDownloadSomethingTemplate({token, route, url, setPercentage, setProgress, fileName, fileType, catchFunction})

export const apiDownloadTeachersExcel = ({
  token,
  route = "teacher/download",
  url,
  setPercentage,
  setProgress,
  catchFunction,
  fileName = "Teachers",
  fileType="xlsx"
}) => apiDownloadSomethingTemplate({token, route, url, setPercentage, setProgress, fileName, fileType, catchFunction})

export const apiDownloadTemplatePdf = ({
  token,
  route = "competencetemplate/preview",
  url,
  setPercentage,
  setProgress,
  catchFunction,
  fileName = "template",
  fileType = 'pdf'
}) => apiDownloadSomethingTemplate({token, route, url, setPercentage, setProgress, fileName, fileType, catchFunction})

export const apiDownloadProofPdf = ({
  token,
  route = "proofofcompetence/pdf",
  url,
  setPercentage,
  setProgress,
  catchFunction,
  fileName = "proof",
  fileType = 'pdf'
}) => apiDownloadSomethingTemplate({token, route, url, setPercentage, setProgress, fileName, fileType, catchFunction})

export const apiDownloadStudentAttendanceExcel = ({
  token,
  route = "attendance/student/download",
  url,
  setPercentage,
  setProgress,
  catchFunction,
  fileName = "student-attendance",
  fileType = 'xlsx'
}) => apiDownloadSomethingTemplate({token, route, url, setPercentage, setProgress, fileName, fileType, catchFunction})

export const apiDownloadClassAttendanceExcel = ({
  token,
  route = "attendance/class/download",
  url,
  setPercentage,
  setProgress,
  catchFunction,
  fileName = "class-attendance",
  fileType = 'xlsx'
}) => apiDownloadSomethingTemplate({token, route, url, setPercentage, setProgress, fileName, fileType, catchFunction})

export const apiDownloadClassGradesExcel = ({
  token,
  route = "grade/class/download",
  url,
  setPercentage,
  setProgress,
  catchFunction,
  fileName = "class-grades",
  fileType = 'xlsx'
}) => apiDownloadSomethingTemplate({token, route, url, setPercentage, setProgress, fileName, fileType, catchFunction})

export const apiDownloadSalaryExcel = ({
  token,
  route = "salary/download",
  url,
  setPercentage,
  setProgress,
  catchFunction,
  fileName = "salary",
  fileType = 'xlsx'
}) => apiDownloadSomethingTemplate({token, route, url, setPercentage, setProgress, fileName, fileType, catchFunction})
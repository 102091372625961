import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { apiGetLessonsByFilters } from "../api/apiCommands"

export const getLessonsState = createAsyncThunk(
  'lessons/getLessonsState',
  async ({token, url}) => {
    return apiGetLessonsByFilters(token, url)
  }
)

const initialState = {
  totalItems: 0,
  filteredItems: 0,
  items: [],
  status: null,
  error: null
}

const lessonsSlice = createSlice({
  name: 'lessons',

  initialState,

  reducers: {
      setNewStatus(state, action) {
        state.status = action.payload
      },

      nullifyLessons(state) {
        state.totalItems = initialState.totalItems
        state.filteredItems = initialState.filteredItems
        state.items = initialState.items
        state.status = initialState.status
        state.error = initialState.error
      }
    },

    extraReducers: {
      [getLessonsState.pending]: (state) => {
        state.status = 'loading'
      },

      [getLessonsState.fulfilled]: (state, action) => {
        const response = action.payload

        state.totalItems = response.totalItems
        state.filteredItems = response.filteredItems
        state.status = 'success'
        state.error = null
        state.items = response.items
      },

      [getLessonsState.rejected]: (state, action) => {
        state.status = 'failed'
        state.error = action.error.message
      },
    }
})

export default lessonsSlice.reducer
export const { setNewStatus, nullifyLessons } = lessonsSlice.actions

import React from 'react'
import { useTranslation } from 'react-i18next'

import { ReactComponent as ReactConfSvg } from '../assets/icon-lesson-conf.svg'

export const GratitudePage = ({ message, gratitude }) => {
  const { t } = useTranslation()

  const closeCurrentTab = () => {
    window.open("about:blank", "_self");
    window.close();
  }

  return (
    <section className='gratitude gratitude-section'>
      <div className='gratitude__container'>
        <ReactConfSvg />
        <span className='gratitude__message'>{message}</span>
        <span className='gratitude__gratitude-description'>{gratitude}</span>

        <button className="gratitude__home-link button" onClick={() => closeCurrentTab()}>
          {t(`closePage`)}
        </button>
      </div>
    </section>
  )
}

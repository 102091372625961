import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { apiGetClassGroups } from '../api/apiCommands'

export const getClassGroupsState = createAsyncThunk(
    'classGroups/getClassGroupsState',
    async ({token, url}) => {
        return apiGetClassGroups(token, url)
    }
)

const initialState = {
    totalItems: 0,
    filteredItems: 0,
    items: [],
    status: null,
    error: null
}

const classGroupsSlice = createSlice({
    name: 'classGroups',

    initialState,

    reducers: {
        setNewStatus(state, action) {
            state.status = action.payload
        },

        nullifyRooms(state) {
            state.totalClasses = initialState.totalClasses
            state.filteredItems = initialState.filteredItems
            state.items = initialState.items
            state.status = initialState.status
            state.error = initialState.error
        }
    },

    extraReducers: {
        [getClassGroupsState.pending]: (state, action) => {
            state.status = 'loading'
        },

        [getClassGroupsState.fulfilled]: (state, action) => {
            const response = action.payload

            state.totalClasses = response.totalItems
            state.filteredItems = response.filteredItems
            state.items = response.items
            state.status = 'success'
            state.error = null
        },

        [getClassGroupsState.rejected]: (state, action) => {
            state.status = 'failed'
            state.error = action.error.message
        },
    }
})

export default classGroupsSlice.reducer
export const { setNewStatus, nullifyRooms } = classGroupsSlice.actions

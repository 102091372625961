import React from 'react'
import {Switch, Route, Redirect} from 'react-router-dom'

import { StudentsPage } from '../pages/studentPage'

export const useStudentRoutes = () => {  
  return (
    <Switch>
      <Route exact path="/student/timetable">
        <StudentsPage />
      </Route>
      <Route exact path="/student/notifications">
        <StudentsPage />
      </Route>
      <Route exact path="/student/grades">
        <StudentsPage />
      </Route>
      <Route exact path="/student/attendance">
        <StudentsPage />
      </Route>
      <Route exact path="/student/attendance/log">
        <StudentsPage />
      </Route>
      <Route exact path="/student/lessons">
        <StudentsPage />
      </Route>
      <Route exact path="/student/profile">
        <StudentsPage />
      </Route>
      <Redirect to="/student/profile" />
    </Switch>
  )
}
import React from 'react'
import classnames from "classnames"

export const HoursTypeSwitch = ({ hoursTypes, activeHours, setActiveHours }) => {
  const layout = Object.values(hoursTypes).map((hourType, index) =>
    <div
      key={`hour-type-index-${index}`}
      onClick={() => { setActiveHours(hourType) }}
      className={
        classnames( "dashboard__hours-switch-item", {
          "dashboard__hours-switch-item_active": activeHours === hourType
        })
      }
    >
      {hourType}
    </div>
  )

  return (
    <div className="dashboard__hours-switch">
      <div className="dashboard__hours-switch-container">
        {layout}
      </div>
    </div>
  )
}

import React, { useState, lazy, Suspense } from 'react'
import { Route, Switch } from 'react-router-dom'
import { useTranslation } from "react-i18next"
import { CSSTransition } from 'react-transition-group'
import classnames from 'classnames'

import { PageContext } from './pageContext'
import { HeaderComponent } from '../components/common/headerComponent'
import { LoadingComponent } from '../components/common/loadingComponents/loadingComponent'
import { AsideNavigationComponent } from '../components/admin-components/asideComponent/asideComponent'

const SemesterMainComponent = lazy(() => import('../components/admin-components/semesterPage/mainComponent'))
const SubjectsMainComponent = lazy(() => import('../components/admin-components/lessonsPage/lessonsMainComponent'))
const StudentsMainComponent = lazy(() => import('../components/admin-components/studentsPage/stundetsMainComponent'))
const ClassMainComponent = lazy(() => import('../components/admin-components/classPage/classMainComponent'))
const TeachersMainComponent = lazy(() => import('../components/admin-components/teachersPage/teacherMainComponent'))
const HolidaysMainComponent = lazy(() => import('../components/admin-components/holidaysPage/holidaysMainComponent'))
const NewTimetablesMainComponent = lazy(() => import('../components/admin-components/newTimetables/newTimetableMainComponent'))
const AttendancePageMainComponent = lazy(() => import('../components/common/attendancePage/attendanceMainComponent'))
const GradesPageMainComponent = lazy(() => import('../components/common/gradesPage/gradesMainComponent'))
const CompetencePageMainComponent = lazy(() => import('../components/common/competencePage/competenceMainComponent'))
const ProofMainPageComponent = lazy(() => import('../components/common/proofCompetencePage/proofMainPageComponent'))
const ReportMainCompoent = lazy(() => import('../components/common/reportPage/reportMainComponent'))
const NotificationsMainComponent = lazy(() => import('../components/admin-components/notificationPage/notificationsMainComponent'))
const SalaryMainComponent = lazy(() => import('../components/common/salaryPage/salaryMainComponent'))
const InvoiceMainComponent = lazy(() => import('../components/common/InvoisePage/invoiceMainComponent'))
const ModulesMainComponent = lazy(() => import('../components/common/modulesPage/moduleMainComponent'))
const LessonsMainComponent = lazy(() => import('../components/common/lessonsPage/lessonsMainComponent'))

export const ASIDE_NAVIGATION_GROUPS = {
  users: 'users',
  organization: 'organization',
  environment: 'environment',
  performance: 'performance',
  managment: 'managment'
}

export const AdminPage = () => {
  const {i18n} = useTranslation()

  const [overflowProperties, setOverflowProperties] = useState(false)
  const [asideIsOpen, setAsideIsOpen] = useState(false)
  const [pageHeader, setPageHeader] = useState(null)
  const [activeNavigationProp, setActiveNavigationProp] = useState(null)

  const changeLanguage = (language) => {
    i18n.changeLanguage(language);
  }

  const changeOverflowStatus = (bool) => {
    setOverflowProperties(bool)
  }

  const changeAsideStatus = (bool) => {
    setAsideIsOpen(bool)

    if (bool) {
      document.body.style.overflow = "hidden"
    } else {
      document.body.style.overflow = "auto"
    }
  }

  const ASIDE_LINKS_LIST = {
    [ASIDE_NAVIGATION_GROUPS.users]: [
      {
        label: null,
        links: [
          {
            url: 'students',
            urlName: 'students'
          },
          {
            url: 'teachers',
            urlName: 'teachers'
          }
        ]
      },
    ],
    [ASIDE_NAVIGATION_GROUPS.organization]: [
      {
        label: null,
        links: [
          {
            url: 'class',
            urlName: 'class'
          }
        ]
      },
      {
        label: 'Academic year',
        links: [
          {
            url: 'academic-year/semesters',
            urlName: 'semesters'
          },
          {
            url: 'academic-year/courses',
            urlName: 'courses'
          }
        ]
      },
      {
        label: 'Timetables',
        links: [
          {
            url: 'timetable/apprentice',
            urlName: 'apprentice'
          },
          {
            url: 'timetable/full-time',
            urlName: 'fullTime'
          },
          {
            url: 'timetable/further',
            urlName: 'further'
          },
        ]
      },
      {
        label: null,
        links: [
          {
            url: 'holidays',
            urlName: 'holidays'
          }
        ]
      },
    ],
    [ASIDE_NAVIGATION_GROUPS.environment]: [
      {
        label: 'Lesson components',
        links: [
          {
            url: 'lessons/subjects',
            urlName: 'subjects'
          },
          {
            url: 'lessons/rooms',
            urlName: 'rooms'
          },
          {
            url: 'lessons/book-room',
            urlName: 'bookRoom'
          },
        ]
      },
      {
        label: 'Competence',
        links: [
          {
            url: 'competence/cards',
            urlName: 'cards'
          },
          {
            url: 'competence/templates',
            urlName: 'templates'
          },
        ]
      },
      {
        label: null,
        links: [
          {
            url: 'module',
            urlName: 'module'
          },
        ]
      },
    ],
    [ASIDE_NAVIGATION_GROUPS.performance]: [
      {
        label: null,
        links: [
          {
            url: 'grades',
            urlName: 'grades'
          },
          {
            url: 'attendance',
            urlName: 'attendance'
          },
          {
            url: 'declines',
            urlName: 'lessons'
          }
        ]
      }
    ],
    [ASIDE_NAVIGATION_GROUPS.managment]: [
      {
        label: null,
        links: [
          {
            url: 'salary',
            urlName: 'salary'
          }
        ]
      },
      {
        label: 'invoice',
        links: [
          {
            url: 'invoice/bexio',
            urlName: 'bexio'
          },
          {
            url: 'invoice/template',
            urlName: 'templates'
          },
          {
            url: 'invoice/product',
            urlName: 'product'
          }
        ]
      },
      {
        label: 'notifications',
        links: [
          {
            url: 'notifications/emails',
            urlName: 'email'
          },
          {
            url: 'notifications/cronjobs',
            urlName: 'cronjobs'
          }
        ]
      }
    ],
  }

  const pageLoader = <div className="page-main__loader"><LoadingComponent/></div>

  return (
    <PageContext.Provider value={{changeOverflowStatus, overflowProperties, setPageHeader}}>
      <div className="admin page">
        <CSSTransition in={overflowProperties} timeout={300} classNames="popup-animation" unmountOnExit>
          <div
            className={classnames("page__overflow",
              {"page__overflow--active": overflowProperties}
            )}
          >
          </div>
        </CSSTransition>

        <AsideNavigationComponent
          className="page__aside"
          pageType={'admin'}
          overflowProperties={overflowProperties}
          changeAsideStatus={changeAsideStatus}
          activeNavigationProp={activeNavigationProp}
          setActiveNavigationProp={setActiveNavigationProp}
          asideIsOpen={asideIsOpen}
          asideNavigationGroups={ASIDE_NAVIGATION_GROUPS}
          asideLinksList={ASIDE_LINKS_LIST}
        />

        <div className="page__main-container">
          <header className="page__header header">
            <HeaderComponent
              changeLanguage={changeLanguage}
              overflowProperties={overflowProperties}
              changeAsideStatus={changeAsideStatus}
              pageHeader={pageHeader}
            />
          </header>

          <main className="page__content">
            <Switch>
              <Route path="/admin/students">
                <Suspense fallback={pageLoader}>
                  <StudentsMainComponent setPageHeader={setPageHeader} setActiveNavigationProp={setActiveNavigationProp}/>
                </Suspense>
              </Route>
              <Route path="/admin/teachers">
                <Suspense fallback={pageLoader}>
                  <TeachersMainComponent overflowProperties={overflowProperties} setPageHeader={setPageHeader} setActiveNavigationProp={setActiveNavigationProp} />
                </Suspense>
              </Route>
              <Route path="/admin/timetable">
                <Suspense fallback={pageLoader}>
                  <NewTimetablesMainComponent overflowProperties={overflowProperties} setPageHeader={setPageHeader} setActiveNavigationProp={setActiveNavigationProp}/>
                </Suspense>
              </Route>
              <Route path="/admin/academic-year">
                <Suspense fallback={pageLoader}>
                  <SemesterMainComponent overflowProperties={overflowProperties} setPageHeader={setPageHeader} setActiveNavigationProp={setActiveNavigationProp}/>
                </Suspense>
              </Route>
              <Route path="/admin/class">
                <Suspense fallback={pageLoader}>
                  <ClassMainComponent overflowProperties={overflowProperties} setPageHeader={setPageHeader} setActiveNavigationProp={setActiveNavigationProp} />
                </Suspense>
              </Route>
              <Route path="/admin/class/report/:classId">
                <Suspense fallback={pageLoader}>
                  <ReportMainCompoent overflowProperties={overflowProperties} setPageHeader={setPageHeader} setActiveNavigationProp={setActiveNavigationProp}  />
                </Suspense>
              </Route>
              <Route path="/admin/salary">
                <Suspense fallback={pageLoader}>
                  <SalaryMainComponent overflowProperties={overflowProperties} setPageHeader={setPageHeader} setActiveNavigationProp={setActiveNavigationProp}  />
                </Suspense>
              </Route>
              <Route path="/admin/lessons">
                <Suspense fallback={pageLoader}>
                  <SubjectsMainComponent overflowProperties={overflowProperties} setPageHeader={setPageHeader} setActiveNavigationProp={setActiveNavigationProp}  />
                </Suspense>
              </Route>
              <Route exact path="/admin/holidays">
                <Suspense fallback={pageLoader}>
                  <HolidaysMainComponent setActiveNavigationProp={setActiveNavigationProp} />
                </Suspense>
              </Route>
              <Route path="/admin/notifications">
                <Suspense fallback={pageLoader}>
                  <NotificationsMainComponent overflowProperties={overflowProperties} setPageHeader={setPageHeader} setActiveNavigationProp={setActiveNavigationProp}  />
                </Suspense>
              </Route>
              <Route path="/admin/grades">
                <Suspense fallback={pageLoader}>
                  <GradesPageMainComponent overflowProperties={overflowProperties} setPageHeader={setPageHeader} setActiveNavigationProp={setActiveNavigationProp}  />
                </Suspense>
              </Route>
              <Route exact path="/admin/attendance">
                <Suspense fallback={pageLoader}>
                  <AttendancePageMainComponent overflowProperties={overflowProperties} setPageHeader={setPageHeader} setActiveNavigationProp={setActiveNavigationProp}  />
                </Suspense>
              </Route>
              <Route path="/admin/competence">
                <Suspense fallback={pageLoader}>
                  <CompetencePageMainComponent overflowProperties={overflowProperties} setPageHeader={setPageHeader} setActiveNavigationProp={setActiveNavigationProp}  />
                </Suspense>
              </Route>
              <Route exact path="/admin/proof/:proofId">
                <Suspense fallback={pageLoader}>
                  <ProofMainPageComponent overflowProperties={overflowProperties} setPageHeader={setPageHeader} setActiveNavigationProp={setActiveNavigationProp}  />
                </Suspense>
              </Route>
              <Route exact path="/admin/assessment/:proofId">
                <Suspense fallback={pageLoader}>
                  <ProofMainPageComponent overflowProperties={overflowProperties} setPageHeader={setPageHeader} setActiveNavigationProp={setActiveNavigationProp}  />
                </Suspense>
              </Route>
              <Route path="/admin/report/student/:studentId">
                <Suspense fallback={pageLoader}>
                  <ReportMainCompoent overflowProperties={overflowProperties} setPageHeader={setPageHeader} setActiveNavigationProp={setActiveNavigationProp}  />
                </Suspense>
              </Route>
              <Route path="/admin/invoice">
                <Suspense fallback={pageLoader}>
                  <InvoiceMainComponent overflowProperties={overflowProperties} setPageHeader={setPageHeader} setActiveNavigationProp={setActiveNavigationProp}  />
                </Suspense>
              </Route>
              <Route path="/admin/report/class/:classId">
                <Suspense fallback={pageLoader}>
                  <ReportMainCompoent overflowProperties={overflowProperties} setPageHeader={setPageHeader} setActiveNavigationProp={setActiveNavigationProp}  />
                </Suspense>
              </Route>
              <Route exact path="/admin/module">
                <Suspense fallback={pageLoader}>
                  <ModulesMainComponent overflowProperties={overflowProperties} setPageHeader={setPageHeader} setActiveNavigationProp={setActiveNavigationProp}  />
                </Suspense>
              </Route>
              <Route exact path="/admin/declines">
                <Suspense fallback={pageLoader}>
                  <LessonsMainComponent overflowProperties={overflowProperties} setPageHeader={setPageHeader} setActiveNavigationProp={setActiveNavigationProp}  />
                </Suspense>
              </Route>
            </Switch>
          </main>
        </div>
      </div>
    </PageContext.Provider>
  )
}

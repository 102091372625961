// basic configuration for provisioning with Azure Active Directory

const BASE_URL = process.env.REACT_APP_BASE_URL
const TENANT_ID = process.env.REACT_APP_TENANT_ID
const CLIENT_ID = process.env.REACT_APP_CLIENT_ID
const SCOPES = process.env.REACT_APP_SCOPES

export const azureConfig = {
  auth: {
    clientId: CLIENT_ID,
    authority: `https://login.microsoftonline.com/${TENANT_ID}`,
    redirectUri: BASE_URL,
    postLogoutRedirectUri: BASE_URL,
    protocolMode: "OIDC"
  },
  cache: {
    cacheLocation: "localStorage",
    storeAuthStateInCookie: true
  }
}

// customization for query requests with Azure Active Directory

export const scopes = [`api://${SCOPES}`]

// vocabulary for possible user roles

export const USER_ROLE_MAP = {
  admin: 'app-role-admin',
  teacher: 'app-role-teacher',
  student: 'app-role-student',
  userUndefiend: undefined,
  null: undefined
}
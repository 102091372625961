import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { apiGetCompetenceTemplates } from '../api/apiCommands'

export const getCompetenceTemplatesState = createAsyncThunk(
  'attendanceStudents/getCompetenceTemplatesState',
  async ({token, url}) => {
    return apiGetCompetenceTemplates(token, url)
  }
)

const initialState = {
  totalAttendanceStudents: 0,
  filteredItems: 0,
  items: [],
  status: null,
  error: null
}

const competenceTemplatesSlice = createSlice({
  name: 'attendanceStudents',

  initialState,

  reducers: {
    setNewStatus(state, action) {
      state.status = action.payload
    },

    nullifyCompetenceTemplatesList(state, action) {
      state.items = []
    }
  },

  extraReducers: {
    [getCompetenceTemplatesState.pending]: (state, action) => {
      state.status = 'loading'
    },

    [getCompetenceTemplatesState.fulfilled]: (state, action) => {
      const response = action.payload

      state.items = response.items
      state.status = 'success'
      state.error = null
    },

    [getCompetenceTemplatesState.rejected]: (state, action) => {
      state.status = 'failed'
      state.error = action.error.message
    },
  }
})

export default competenceTemplatesSlice.reducer
export const { setNewStatus, nullifyCompetenceTemplatesList } = competenceTemplatesSlice.actions
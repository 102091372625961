import React, { Fragment, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { NavLink } from 'react-router-dom'
import classNames from 'classnames'

import { ReactComponent as ReactStudentSvg } from '../../../assets/icon-student.svg'
import { ReactComponent as ReactTeacherSvg } from '../../../assets/icon-teacher.svg'
import { ReactComponent as ReactTimetableSvg } from '../../../assets/icon-tametable.svg'
import { ReactComponent as ReactSemesterSvg } from '../../../assets/icon-semester.svg'
import { ReactComponent as ReactClassSvg } from '../../../assets/icon-class.svg'
import { ReactComponent as ReactSalarySvg } from '../../../assets/icon-salary.svg'
import { ReactComponent as ReactLessonsSvg } from '../../../assets/icon-lesson.svg'
import { ReactComponent as ReactHolidaysSvg } from '../../../assets/icon-holiday.svg'
import { ReactComponent as ReactDocumentsSvg } from '../../../assets/icon-document.svg'
import { ReactComponent as ReactGradesSvg } from '../../../assets/icon-grades.svg'
import { ReactComponent as ReactAttendanceSvg } from '../../../assets/icon-attendance.svg'
import { ReactComponent as ReactProfileSvg } from '../../../assets/icon-user2.svg'
import { ReactComponent as ReactCompareSvg } from '../../../assets/icon-compare.svg'
import { ReactComponent as ReactModuleSvg } from '../../../assets/icon-module.svg'
import { ReactComponent as ReactDashboardSvg } from '../../../assets/dashboard.svg'
import { ReactComponent as ReactInvoiceSvg } from '../../../assets/icon-invoice.svg'
import { ReactComponent as ReactUsersSvg } from '../../../assets/icon-users2.svg'
import { ReactComponent as ReactOrganizationSvg } from '../../../assets/icon-organization2.svg'
import { ReactComponent as ReactEnvironmentSvg } from '../../../assets/icon-environment2.svg'
import { ReactComponent as ReactPerfomanceSvg } from '../../../assets/icon-perfomance.svg'
import { ReactComponent as ReactManagmentSvg } from '../../../assets/icon-managment.svg'
import { ReactComponent as ReactRoomSvg } from '../../../assets/icon-rooms.svg'
import { ReactComponent as ReactBookRoomSvg } from '../../../assets/icon-book-room.svg'
import { ReactComponent as ReactSubjectSvg } from '../../../assets/icon-subject.svg'
import { ReactComponent as ReactCardSvg } from '../../../assets/icon-card.svg'
import { ReactComponent as ReactTemplateSvg } from '../../../assets/icon-template.svg'
import { ReactComponent as ReactProductSvg } from '../../../assets/icon-product.svg'
import { ReactComponent as ReactEmailSvg } from '../../../assets/icon-email.svg'
import { ReactComponent as ReactNavigationSvg } from '../../../assets/icon-navigation.svg'
import { useEffect } from 'react'

export const ASIDE_CONTENT_SVG_LIST = {
  students: <ReactStudentSvg />,
  teachers: <ReactTeacherSvg />,
  timetable: <ReactTimetableSvg />,
  apprentice: <ReactTimetableSvg />,
  fullTime: <ReactTimetableSvg />,
  further: <ReactTimetableSvg />,
  semesters: <ReactSemesterSvg />,
  courses: <ReactSemesterSvg />,
  class: <ReactClassSvg />,
  salary: <ReactSalarySvg />,
  lessons: <ReactLessonsSvg />,
  subjects: <ReactSubjectSvg />,
  rooms: <ReactRoomSvg />,
  bookRoom: <ReactBookRoomSvg />,
  cards: <ReactCardSvg />,
  templates: <ReactTemplateSvg />,
  holidays: <ReactHolidaysSvg />,
  grades: <ReactGradesSvg />,
  attendance: <ReactAttendanceSvg />,
  profile: <ReactProfileSvg />,
  dashboard: <ReactDashboardSvg/>,
  competence: <ReactCompareSvg/>,
  bexio: <ReactInvoiceSvg />,
  module: <ReactModuleSvg />,
  declines: <ReactLessonsSvg />,
  product: <ReactProductSvg />,
  email: <ReactEmailSvg />,
  cronjobs: <ReactDocumentsSvg />,
  navigation: <ReactNavigationSvg />
}

const AsideContent = ({
  group,
  pageType,
  changeAsideStatus,
  overflowProperties,
  setActiveNavigationProp,
  activeNavigationProp,
  asideNavigationGroups,
  asideLinksList
}) => {
  const { t } = useTranslation()
  const [contentIsOpen, setContentIsOpen] = useState(pageType === 'teacher' ? true : false)

  const linkCLickHandler = (group) => {
    changeAsideStatus(false)
    setActiveNavigationProp(group)
  }

  const NAVIGATIO_ROUTE_SVG = {
    [asideNavigationGroups.users]: <ReactUsersSvg />,
    [asideNavigationGroups.organization]: <ReactOrganizationSvg />,
    [asideNavigationGroups.environment]: <ReactEnvironmentSvg />,
    [asideNavigationGroups.performance]: <ReactPerfomanceSvg />,
    [asideNavigationGroups.managment]: <ReactManagmentSvg />,
    [asideNavigationGroups.navigation]: <ReactNavigationSvg />,
  }

  useEffect(() =>{
    if (activeNavigationProp === group && pageType === 'admin') {
      setContentIsOpen(true)
    } 
    else if (activeNavigationProp !== group && pageType === 'admin') {
      setContentIsOpen(false)
    }
  }, [activeNavigationProp, group, pageType])

  return (
    <Fragment>
      <button
        className={classNames(
          "aside__navigation-button",
          {"aside__navigation-button--open": contentIsOpen},
          {"aside__navigation-button--active": activeNavigationProp === group},
          {"aside__navigation-button--hidden": pageType !== 'admin'},
        )}
        type='button'
        onClick={() => setContentIsOpen(!contentIsOpen)}
      >
        {NAVIGATIO_ROUTE_SVG[group]}
        <span>{t(`aside.${group}`)}</span>
      </button>

      <div
        className={classNames(
          "aside__content-container",
          {"aside__content-container--open": contentIsOpen}
        )}
      >
        <div className="aside__list-container">
          <ul className="aside__list">
            {asideLinksList[group].map((groupObject, index) => {
              const linksList = groupObject.links
              return (
                <li className="aside__item" key={index}>
                  {groupObject?.label && 
                    <h4>{groupObject?.label}</h4>
                  }

                  {
                    linksList.map((urlObject, index) => {
                      return (
                        <NavLink
                          key={index}
                          className="aside__link"
                          activeClassName="aside__link--active"
                          to={`/${pageType}/${urlObject.url}`}
                          onClick={() => linkCLickHandler(group)}
                          tabIndex={overflowProperties ? -1 : 0}
                        >
                          {ASIDE_CONTENT_SVG_LIST?.[urlObject.urlName]}
                          <span className="aside__link-span">
                            {t(`aside.${urlObject.urlName}`)}
                          </span>
                        </NavLink>
                      )
                    })
                  }
                </li>
              )})}
          </ul>
        </div>
      </div>
    </Fragment>
  )
}

export default AsideContent
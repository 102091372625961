import React, {useEffect, useState} from 'react'
import Chart from "react-apexcharts";
import {useSelector} from "react-redux"
import {getDaysArray} from "../../../utils/utils";
import {LineCalendar} from './lineCalendar'
import {apiGetDailyLessons, apiGetLessonStatistics} from '../../../api/apiCommands'
import { makeQueryParamsUrl } from '../../../utils/utils'
import {HoursInfo} from "./hoursInfo"
import {HoursTypeSwitch} from './hoursSwitch'
import {StatisticPanel} from './statisticPanel'
import moment from "moment";

const hoursTypes = {
  worked: 'worked',
  absent: 'absent',
}

const DashboardMainComponent = () => {
  const successToken = useSelector(state => state.userData.successToken)

  const currentYear = new Date().getFullYear()
  const currentMonth = new Date().getMonth()
  const [calendarDateContext, setCalendarDateContext] = useState(moment())
  const [itIsMonthStats/*, setItIsMonthStats*/] = useState(true)
  const [lessons, setLessons] = useState([])
  const [statistics, setStatistics] = useState([])
  const [activeHours, setActiveHours] = useState(hoursTypes.worked)

  const getAbsencesStatistics = (from, to) => {
    const params = makeQueryParamsUrl({
      from: from,
      to: to,
    })

    apiGetLessonStatistics(successToken, params)
  }

  const makeLessonsStatisticsRequest = (from , to) => {
    const params = makeQueryParamsUrl({
      from: from,
      to: to,
    })

    apiGetLessonStatistics(successToken, params).then(response => {
      setStatistics(response)
    })
  }

  const makeLessonsRequest = (value) => {
    const url = makeQueryParamsUrl({
      StartDate: value,
      EndDate: value
    })

    apiGetDailyLessons(successToken, url)
      .then((response) => {
        const convertedLessons = response.items.map(item => {
          return {
            id: item.id,
            subject: item.subject.name,
            className: item.class.name,
            startTime: `${item.startTime.hours}:${item.startTime.minutes}h`
          }
        })
        setLessons(convertedLessons)
      })
  }

  useEffect(() => {
    const startDate = moment(new Date(currentYear,  0, 1)).format('YYYY-MM-DD[T]HH:mm:ss.SSS[Z]')
    const endDate = moment(new Date(currentYear, 11, 31)).format('YYYY-MM-DD[T]HH:mm:ss.SSS[Z]')

    getAbsencesStatistics(startDate, endDate)
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (calendarDateContext) {
      let selectedYear = Number(calendarDateContext.format("Y"))
      let selectedMonth = Number(Number(calendarDateContext.format("M")))

      const startDate = moment(new Date(selectedYear, selectedMonth - 1, 1)).format('YYYY-MM-DD[T]HH:mm:ss.SSS[Z]')
      const endDate = moment(new Date(selectedYear, selectedMonth, 0)).format('YYYY-MM-DD[T]HH:mm:ss.SSS[Z]')

      makeLessonsStatisticsRequest(startDate, endDate)
      makeLessonsRequest(calendarDateContext.format('YYYY-MM-DD[T]HH:mm:ss.SSS[Z]'))
    }
    // eslint-disable-next-line
  }, [calendarDateContext])

  useEffect(() => {
    if (!itIsMonthStats) {
      const currentYear = Number(calendarDateContext.format("Y"))

      const startDate = moment(new Date(currentYear,  0, 1)).format('YYYY-MM-DD[T]HH:mm:ss.SSS[Z]')
      const endDate = moment(new Date(currentYear, 11, 31)).format('YYYY-MM-DD[T]HH:mm:ss.SSS[Z]')

      makeLessonsStatisticsRequest(startDate, endDate)
    }
    // eslint-disable-next-line
  }, [itIsMonthStats])

  const getConvertedDays = (selectedYear, selectedMonth) => {
    return getDaysArray(selectedYear, selectedMonth).map((currentDay, index) => {
      return moment(new Date(selectedYear, selectedMonth, index + 1)).format("D ddd")
    })
  }

  const getCategories = () => {
    let result = []
    let selectedYear = calendarDateContext ? Number(calendarDateContext.format("Y")) : currentYear
    let selectedMonth = calendarDateContext ? Number(Number(calendarDateContext.format("M"))) : currentMonth

    if (itIsMonthStats) {
      result = getConvertedDays(selectedYear, selectedMonth)
    } else {
      result = moment.monthsShort()
    }

    return result
  }

  const getTemporaryData = () => {
    const selectedYear = calendarDateContext ? Number(calendarDateContext.format("Y")) : currentYear
    const selectedMonth = calendarDateContext ? Number(Number(calendarDateContext.format("M"))) : currentMonth
    const activeHoursMap = {
      'worked': 'totalFinishedHours',
      'absent': 'totalAbsentHours',
    }
    let displayHours = null

    if (itIsMonthStats) {
      const daysInSelectedMonth = getDaysArray(selectedYear, selectedMonth)
      displayHours = Array(daysInSelectedMonth.length).fill(0)

      statistics.forEach(item => {
        if (item[activeHoursMap[activeHours]]) {
          const dayIndex = Number(moment(item.date, 'YYYY-MM-DD[T]HH:mm:ss.SSS[Z]').format("D")) - 1
          displayHours[dayIndex] = item[activeHoursMap[activeHours]]
        }
      })
    } else {
      displayHours = Array(12).fill(0)

      statistics.forEach(item => {
        const monthIndex = Number(moment(item.date, 'YYYY-MM-DD[T]HH:mm:ss.SSS[Z]').format("M")) - 1
        displayHours[monthIndex] += item[activeHoursMap[activeHours]]
      })
    }

    return displayHours
  }

  const temporaryData = getTemporaryData()
  const chartColor = activeHours === hoursTypes.worked ? '#72CD75' : '#e65750'

  const diagramState = {
    series: [{
      name: 'Worked Hours',
      data: temporaryData
    }],
    options: {
      chart: {
        type: 'area',
        height: 350
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        show: true,
        curve: 'smooth',
        width: 2,
        colors: [chartColor]
      },
      xaxis: {
        categories: getCategories(),
        tickAmount: 10,
        labels: {
          rotate: 0,
        }
      },
      yaxis: {
        title: {
          text: ''
        }
      },
      fill: {
        colors: [chartColor],
        opacity: 1
      },
      tooltip: {
        y: {
          formatter: function (val) {
            return `${val} hours`
          }
        }
      }
    },
  };
  /*
  let workedHours = 0;
  diagramState.series[0].data.filter((item) => !!item).forEach((item) => {
    workedHours += item
  })
  */
  return (
    <div className="dashboard__main-component" id="dashboard">
      <div className="dashboard__statistics-wrapper">
        <StatisticPanel
          statistics={statistics}
        />
        <div className="dashboard__diagrams">
          <HoursTypeSwitch
            hoursTypes={hoursTypes}
            activeHours={activeHours}
            setActiveHours={setActiveHours}
          />
          <HoursInfo
            activeHours={activeHours}
            setActiveHours={setActiveHours}
            statistics={statistics}
            temporaryData={temporaryData}
          />
          <Chart options={diagramState.options} series={diagramState.series} type="area" height={350}/>
        </div>
      </div>
      <div className="dashboard__absences">
        <LineCalendar
          chosenDay={calendarDateContext.format('YYYY-MM-DD')}
          setChosenDay={setCalendarDateContext}
        />
        <div className="dashboard__lessons-stats">
          <div className="dashboard__all-lessons">
            <h1 className="dashboard__all-lessons-title">
              Lessons
            </h1>
            <div className="dashboard__all-lessons-list-container">
              {
                lessons.length > 0 ?
                  <ul className="dashboard__all-lessons-list">
                    {
                      lessons.map(item =>
                        <li key={item.id} className="dashboard__all-lessons-item">
                          <div className="dashboard__all-lessons-name">{item.subject}</div>
                          <div className="dashboard__all-lessons-class">{item.className}</div>
                          <div className="dashboard__all-lessons-hours">{item.startTime}</div>
                        </li>
                      )
                    }
                  </ul> :
                  <div className="dashboard__all-lessons-list-empty">No lessons</div>
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default DashboardMainComponent

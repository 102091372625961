import React, { useState, lazy, Suspense } from 'react'
import { Route, Switch } from 'react-router-dom'
import { useTranslation } from "react-i18next"
import classnames from 'classnames'

import {PageContext} from './pageContext'
import {LogoutButton} from '../components/common/util-components/logoutButton'
import {HeaderComponent} from '../components/common/headerComponent'
import {LoadingComponent} from '../components/common/loadingComponents/loadingComponent'
import {ProfilePageComponent} from "../components/teacher-components/profilePage/profilePage"
import DashboardMainComponent from "../components/teacher-components/dashboard/dashboard"
import { AsideNavigationComponent } from '../components/admin-components/asideComponent/asideComponent'

const ReportMainCompoent = lazy(() => import('../components/common/reportPage/reportMainComponent'))
const ClassMainComponent = lazy(() => import('../components/admin-components/classPage/classMainComponent'))
const TimeTableMainComponent = lazy(() => import('../components/teacher-components/timeTablePage/timetableMainComponent'))
const AttendanceMainComponent = lazy(() => import('../components/common/attendancePage/attendanceMainComponent'))
const GradesPageMainComponent = lazy(() => import('../components/common/gradesPage/gradesMainComponent'))
const CompetencePageMainComponent = lazy(() => import('../components/common/competencePage/competenceMainComponent'))
const ProofMainPageComponent = lazy(() => import('../components/common/proofCompetencePage/proofMainPageComponent'))
const SalaryMainComponent = lazy(() => import('../components/common/salaryPage/salaryMainComponent'))

export const TeacherPage = () => {
  const {i18n} = useTranslation()
  const [overflowProperties, setOverflowProperties] = useState(false)
  const [asideIsOpen, setAsideIsOpen] = useState(false)
  const [pageHeader, setPageHeader] = useState(null)
  const [activeNavigationProp, setActiveNavigationProp] = useState(null)

  const changeLanguage = (language) => {
    i18n.changeLanguage(language);
  }

  const changeOverflowStatus = (bool) => {
    setOverflowProperties(bool)

    if (bool) {
      document.body.style.overflow = "hidden"
    } else {
      document.body.style.overflow = "auto"
    }
  }

  const changeAsideStatus = (bool) => {
    setAsideIsOpen(bool)

    if (bool) {
      document.body.style.overflow = "hidden"
    } else {
      document.body.style.overflow = "auto"
    }
  }

  const TEACHER_NAVIGATION_GROUPS = {
    'navigation': 'navigation'
  }
  
  const TEACHER_ASIDE_LINKS_LIST = {
    [TEACHER_NAVIGATION_GROUPS.navigation]: [
      {
        label: null,
        links: [
          {
            url: 'timetable',
            urlName: 'timetable'
          },
          {
            url: 'grades',
            urlName: 'grades'
          },
          {
            url: 'attendance',
            urlName: 'attendance'
          },
          {
            url: 'class',
            urlName: 'class'
          },
          {
            url: 'salary',
            urlName: 'salary'
          },
          {
            url: 'profile',
            urlName: 'profile'
          },
        ]
      },
    ],
  }

  const pageLoader = <div className="page-main__loader"><LoadingComponent/></div>

  return (
    <PageContext.Provider value={{changeOverflowStatus, overflowProperties, setPageHeader}}>
      <div className="teacher page">
        <div
          className={classnames("page__overflow",
            {"page__overflow--active": overflowProperties}
          )}
        >
        </div>

        <aside className={classnames("page__aside", "aside", {"aside--open": asideIsOpen})} >
          <AsideNavigationComponent
            className="page__aside"
            pageType={'teacher'}
            overflowProperties={overflowProperties}
            changeAsideStatus={changeAsideStatus}
            asideIsOpen={asideIsOpen}
            activeNavigationProp={activeNavigationProp}
            setActiveNavigationProp={setActiveNavigationProp}
            asideNavigationGroups={TEACHER_NAVIGATION_GROUPS}
            asideLinksList={TEACHER_ASIDE_LINKS_LIST}
          />
        </aside>

        <div className="page__main-container">
          <header className="page__header header">
            <HeaderComponent 
              changeLanguage={changeLanguage} 
              overflowProperties={overflowProperties}
              changeAsideStatus={changeAsideStatus}
              pageHeader={pageHeader}
            />
          </header>

          <main className="page__content">
            <Switch>
              <Route path="/teacher/timetable">
                <Suspense fallback={pageLoader}>
                  <TimeTableMainComponent overflowProperties={overflowProperties} setPageHeader={setPageHeader} setActiveNavigationProp={setActiveNavigationProp} />
                </Suspense>
              </Route>
              <Route path="/teacher/notifications/">
                <Suspense fallback={pageLoader}>
                  <LogoutButton/>
                </Suspense>
              </Route>
              <Route path="/teacher/grades/">
                <Suspense fallback={pageLoader}>
                  <GradesPageMainComponent overflowProperties={overflowProperties} setPageHeader={setPageHeader} setActiveNavigationProp={setActiveNavigationProp}/>
                </Suspense>
              </Route>
              <Route path="/teacher/attendance/">
                <Suspense fallback={pageLoader}>
                  <AttendanceMainComponent overflowProperties={overflowProperties} setPageHeader={setPageHeader} setActiveNavigationProp={setActiveNavigationProp}/>
                </Suspense>
              </Route>
              <Route path="/teacher/competence">
                <Suspense fallback={pageLoader}>
                  <CompetencePageMainComponent overflowProperties={overflowProperties} setPageHeader={setPageHeader} setActiveNavigationProp={setActiveNavigationProp} />
                </Suspense>
              </Route>
              <Route path="/teacher/class">
                <Suspense fallback={pageLoader}>
                  <ClassMainComponent overflowProperties={overflowProperties} setPageHeader={setPageHeader} setActiveNavigationProp={setActiveNavigationProp}/>
                </Suspense>
              </Route>
              <Route path="/teacher/attendance-log/">
                <Suspense fallback={pageLoader}>
                  <LogoutButton/>
                </Suspense>
              </Route>
              <Route exact path="/teacher/lessons/">
                <LogoutButton/>
              </Route>
              <Route path="/teacher/profile/">
                <Suspense fallback={pageLoader}>
                  <ProfilePageComponent/>
                </Suspense>
              </Route>
              <Route path="/teacher/dashboard/">
                <Suspense fallback={pageLoader}>
                  <DashboardMainComponent/>
                </Suspense>
              </Route>
              <Route exact path="/teacher/proof/:proofId">
                <Suspense fallback={pageLoader}>
                  <ProofMainPageComponent overflowProperties={overflowProperties} setPageHeader={setPageHeader} setActiveNavigationProp={setActiveNavigationProp} />
                </Suspense>
              </Route>
              <Route exact path="/teacher/assessment/:proofId">
                <Suspense fallback={pageLoader}>
                  <ProofMainPageComponent overflowProperties={overflowProperties} setPageHeader={setPageHeader} setActiveNavigationProp={setActiveNavigationProp} />
                </Suspense>
              </Route>
              <Route path="/teacher/report/student/:studentId">
                <Suspense fallback={pageLoader}>
                  <ReportMainCompoent overflowProperties={overflowProperties} setPageHeader={setPageHeader} setActiveNavigationProp={setActiveNavigationProp} />
                </Suspense>
              </Route>
              <Route path="/teacher/report/class/:classId">
                <Suspense fallback={pageLoader}>
                  <ReportMainCompoent overflowProperties={overflowProperties} setPageHeader={setPageHeader} setActiveNavigationProp={setActiveNavigationProp} />
                </Suspense>
              </Route>
              <Route path="/teacher/salary">
                <Suspense fallback={pageLoader}>
                  <SalaryMainComponent overflowProperties={overflowProperties} setPageHeader={setPageHeader} setActiveNavigationProp={setActiveNavigationProp} />
                </Suspense>
              </Route>
            </Switch>
          </main>
        </div>
      </div>
    </PageContext.Provider>
  )
}
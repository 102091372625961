import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { apiGetSpecialHolidays } from '../api/apiCommands'

export const getHolidaysState = createAsyncThunk(
  'holidays/getHolidaysState',
  async ({token, url}) => {
    return apiGetSpecialHolidays(token, url)
  }
)

const initialState = {
  totalHolidays: 0,
  items: [],
  status: null,
  error: null
}

const holidaysSlice = createSlice({
  name: 'holidays',

  initialState,
  
  reducers: {
    setNewStatus(state, action) {
      state.status = action.payload
    },

    nullifyHolidays(state) {
      state.totalHolidays = initialState.totalHolidays
      state.items = initialState.items
      state.status = initialState.status
      state.error = initialState.error
    }
  },

  extraReducers: {
    [getHolidaysState.pending]: (state, action) => {
      state.status = 'loading'
    },

    [getHolidaysState.fulfilled]: (state, action) => {
      const response = action.payload

      if (response.fetchStatus) {
        state.error = 401
      } else {
        state.totalHolidays = response.totalItems
        state.items = response.items
        state.status = 'success'
        state.error = null
      }
    },

    [getHolidaysState.rejected]: (state, action) => {
      state.status = 'failed'
      state.error = action.error.message
    },
  }
})

export default holidaysSlice.reducer
export const { setNewStatus, nullifyHolidays } = holidaysSlice.actions
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { apiGetDailyEvents } from "../api/apiCommands"

export const getDailyEventsState = createAsyncThunk(
  'lessons/getDailyEventsState',
  async ({token, url}) => {
    return apiGetDailyEvents(token, url)
  }
)

export const getAllDailyEventsState = createAsyncThunk(
  'lessons/getAllDailyEventsState',
  async ({token, url}) => {
    return apiGetDailyEvents(token, url)
  }
)

const initialState = {
  totalItems: 0,
  filteredItems: 0,
  items: [],
  allItems: [],
  status: null,
  error: null
}

const dailyEventsSlice = createSlice({
  name: 'lessons',

  initialState,

  reducers: {
      setNewStatus(state, action) {
        state.status = action.payload
      }
    },

    extraReducers: {
      [getDailyEventsState.pending]: (state) => {
        state.status = 'loading'
      },

      [getDailyEventsState.fulfilled]: (state, action) => {
        const response = action.payload

        state.totalItems = response.totalItems
        state.filteredItems = response.filteredItems
        state.status = 'success'
        state.error = null
        state.items = response.items
      },

      [getDailyEventsState.rejected]: (state, action) => {
        state.status = 'failed'
        state.error = action.error.message
      },

      [getAllDailyEventsState.pending]: (state) => {
        state.status = 'loading'
      },

      [getAllDailyEventsState.fulfilled]: (state, action) => {
        const response = action.payload

        state.status = 'success'
        state.error = null
        state.allItems = response.items
        state.totalItems = response.totalItems
        state.filteredItems = response.filteredItems
      },

      [getAllDailyEventsState.rejected]: (state, action) => {
        state.status = 'failed'
        state.error = action.error.message
      },
    }
})

export default dailyEventsSlice.reducer
export const { setNewStatus } = dailyEventsSlice.actions

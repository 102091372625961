import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { apiGetInvoiceTemplates } from '../api/apiCommands'

export const getInvoiceTemplateState = createAsyncThunk(
  'invoiceTemplates/getInvoiceTemplateState',
  async ({token, url}) => {
    return apiGetInvoiceTemplates(token, url)
  }
)

const initialState = {
  totalItems: 0,
  filteredItems: 0,
  items: [],
  status: null,
  error: null
}

const invoiceTemplateSlice = createSlice({
  name: 'invoiceTemplates',

  initialState,
  
  reducers: {
    setNewStatus(state, action) {
      state.status = action.payload
    },

    nullifyInvoiceTemplateState(state) {
      state = Object.assign(state, initialState)
    }
  },

  extraReducers: {
    [getInvoiceTemplateState.pending]: (state, action) => {
      state.status = 'loading'
    },

    [getInvoiceTemplateState.fulfilled]: (state, action) => {
      const response = action.payload

      state.totalItems = response.totalItems
      state.filteredItems = response.filteredItems
      state.status = 'success'
      state.error = null
      state.items = response.items
    },

    [getInvoiceTemplateState.rejected]: (state, action) => {
      state.status = 'failed'
      state.error = action.error.message
    },
  }
})

export default invoiceTemplateSlice.reducer
export const { setNewStatus, nullifyInvoiceTemplateState } = invoiceTemplateSlice.actions

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { apiGetSubjects } from '../api/apiCommands'

export const getSubjectState = createAsyncThunk(
  'subjects/getSubjectState',
  async ({token, url}) => {
    return apiGetSubjects(token, url)
  }
)

const initialState = {
  totalSubjects: 0,
  items: [],
  status: null,
  error: null
}

const subjectSlice = createSlice({
  name: 'subjects',

  initialState,
  
  reducers: {
    setNewStatus(state, action) {
      state.status = action.payload
    },

    nullifySubjects(state) {
      state.totalSubjects = initialState.totalRooms
      state.items = initialState.items
      state.status = initialState.status
      state.error = initialState.error
    }
  },

  extraReducers: {
    [getSubjectState.pending]: (state, action) => {
      state.status = 'loading'
    },

    [getSubjectState.fulfilled]: (state, action) => {
      const response = action.payload

      if (response.fetchStatus) {
        state.error = 401
      } else {
        state.totalSubjects = response.totalItems
        state.items = response.items
        state.status = 'success'
        state.error = null
      }
    },

    [getSubjectState.rejected]: (state, action) => {
      state.status = 'failed'
      state.error = action.error.message
    },
  }
})

export default subjectSlice.reducer
export const { setNewStatus, nullifySubjects } = subjectSlice.actions
import React, { useState, lazy, Suspense, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { useLocation } from 'react-router'

import { apiGetProofOfCompetence } from '../api/apiCommands'
import { LoadingComponent } from '../components/common/loadingComponents/loadingComponent'
import { PROOF_STATUTES } from '../utils/constants'

const ProofMainPageComponent = lazy(() => import('../components/common/proofCompetencePage/proofMainPageComponent'))
const ErrorPageComponent = lazy(() => import('../components/common/util-components/errorComponent'))

export const ProofGlobalPage = ({ setMessage }) => {
  const { t } = useTranslation()
  const location = useLocation()
  const { proofId } = useParams()
  const successToken = useSelector(state => state.userData.successToken)

  const [pageIsAlive, setPageIsAlive] = useState(false)
  const [, setPageHeader] = useState(null)
  const [isSimpleVersion, setIsSimpleVersion] = useState(true)
  const [isPending, setIsPending] = useState(true)

  const updateProofInfo = () => {
    apiGetProofOfCompetence(successToken, proofId).then((response) => {
      const currentStage = response.stage

      if (location.pathname.includes("proof") && currentStage.value < PROOF_STATUTES.PROOF_FILLED_BY_STUDENT) {
        setPageIsAlive(true)
        setMessage({
          description: t(`proofOfCompetencePage.studentGraditude.fiiledIsConfirmed`),
          gratitude: t(`proofOfCompetencePage.studentGraditude.filledGraditude`),
        })
      }
      else if (location.pathname.includes("assessment") 
        && (currentStage.value >= PROOF_STATUTES.PROOF_APPROVED_BY_TEACHER
        && currentStage.value < PROOF_STATUTES.PROOF_APPROVED_BY_STUDENT)) {
        setPageIsAlive(true)
        setIsSimpleVersion(false)

        setMessage({
          description: t(`proofOfCompetencePage.studentGraditude.approveIsConfirmed`),
          gratitude: t(`proofOfCompetencePage.studentGraditude.approveGraditude`),
        })
      }

      setIsPending(false)
    })
    .catch(() => {
      setIsPending(false)
      setPageIsAlive(false)
    })
  }

  useEffect(() => {
    updateProofInfo()

    // eslint-disable-next-line
  }, [])

  const pageLoader = <div className="page-main__loader"><LoadingComponent/></div>

  return (
    <section className='global-section'>
      {
      isPending ? pageLoader :
      pageIsAlive ?
        <Suspense fallback={pageLoader}>
          <ProofMainPageComponent setPageHeader={setPageHeader} isSimpleVersion={isSimpleVersion} />
        </Suspense> 
        :
        <Suspense fallback={pageLoader}>
          <ErrorPageComponent />
        </Suspense>
      }
    </section>
  )
}
import {createAsyncThunk, createSlice} from "@reduxjs/toolkit"
import { apiGetDailyLessons, apiGetDailyLessonHours } from "../api/apiCommands"

export const getDailyLessonsState = createAsyncThunk(
  'dailyLessons/getDailyLessonsState',
  async ({token, url}) => {
    return apiGetDailyLessons(token, url)
  }
)

export const getDailyLessonsHoursState = createAsyncThunk(
  'dailyLessons/getDailyLessonsHoursState',
  async ({token, date}) => {
    return apiGetDailyLessonHours(token, date)
  }
)

const initialState = {
  totalItems: 0,
  filteredItems: 0,
  items: [],
  dateTimes: [],
  status: null,
  error: null
}

const dailyLessonsSlice = createSlice({
  name: 'dailyLessons',

  initialState,

  reducers: {
      setNewStatus(state, action) {
        state.status = action.payload
      },

      nullifyLessons(state) {
        state.totalItems = initialState.totalItems
        state.filteredItems = initialState.filteredItems
        state.items = initialState.items
        state.status = initialState.status
        state.error = initialState.error
      }
    },

    extraReducers: {
      [getDailyLessonsState.pending]: (state) => {
        state.status = 'loading'
      },

      [getDailyLessonsHoursState.pending]: (state) => {
        state.status = 'loading'
      },

      [getDailyLessonsState.fulfilled]: (state, action) => {
        const response = action.payload

        state.totalItems = response.totalItems
        state.filteredItems = response.filteredItems
        state.status = 'success'
        state.error = null
        state.items = response.items
      },

      [getDailyLessonsHoursState.fulfilled]: (state, action) => {
        const response = action.payload

        state.dateTimes = response
        state.status = 'success'
        state.error = null
      },

      [getDailyLessonsState.rejected]: (state, action) => {
        state.status = 'failed'
        state.error = action.error.message
      },

      [getDailyLessonsHoursState.rejected]: (state, action) => {
        state.status = 'failed'
        state.error = action.error.message
      }
    }
})


export default dailyLessonsSlice.reducer
export const { setNewStatus, nullifyLessons } = dailyLessonsSlice.actions
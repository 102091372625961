import i18n from "i18next"
import Backend from 'i18next-http-backend'
import LanguageDetector from 'i18next-browser-languagedetector'
import { initReactI18next } from 'react-i18next'

const DEFAULT_LANGUAGE = localStorage.getItem('language') 
  ? localStorage.getItem('language')
  : process.env.REACT_APP_DEFAULT_LANGUAGE

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use (initReactI18next)
  .init({
    fallbackLng: {
      'de': ['de'],
      'es': ['de'],
      'en': ['en'],
      'default': [DEFAULT_LANGUAGE]
    },
    debug: false,
    detection: {
      order: ['queryString', 'cookie'],
      cache: ['cookie']
    },
    interpolation: {
      escapeValue: false
    }
  })

export default i18n;
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { apiGetRooms } from '../api/apiCommands'

export const getRoomsState = createAsyncThunk(
  'rooms/getRoomsState',
  async (token) => {
    return apiGetRooms(token)
  }
)

const initialState = {
  totalRooms: 0,
  items: [],
  status: null,
  error: null
}

const roomSlice = createSlice({
  name: 'rooms',

  initialState,
  
  reducers: {
    setNewStatus(state, action) {
      state.status = action.payload
    },

    nullifyRooms(state) {
      state.totalRooms = initialState.totalRooms
      state.items = initialState.items
      state.status = initialState.status
      state.error = initialState.error
    }
  },

  extraReducers: {
    [getRoomsState.pending]: (state, action) => {
      state.status = 'loading'
    },

    [getRoomsState.fulfilled]: (state, action) => {
      const response = action.payload

      state.totalRooms = response.totalItems
      state.items = response.items
      state.status = 'success'
      state.error = null
    },

    [getRoomsState.rejected]: (state, action) => {
      state.status = 'failed'
      state.error = action.error.message
    },
  }
})

export default roomSlice.reducer
export const { setNewStatus, nullifyRooms } = roomSlice.actions
import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { UserAgentApplication } from "msal"
import { BrowserRouter, Route } from 'react-router-dom'
import { QueryParamProvider } from 'use-query-params'
import { useGlobalRoutes } from './routes/globalRoutes'
import { azureConfig, scopes } from './azure/azureConfig'
import { getUserState } from './redux/userSlice'

import "react-datepicker/dist/react-datepicker.css"

function App() {
  const dispatch = useDispatch()
  const routes = useGlobalRoutes()

  useEffect(() => {
    const userAgentApplication = new UserAgentApplication(azureConfig)
    const userRoleFromStorage = localStorage.getItem('userRole')

    if (userRoleFromStorage) {
      dispatch(getUserState())
      .catch(() => {
        userAgentApplication.loginPopup({scopes}).then((response) => {
          localStorage.setItem('userRole', response?.idToken?.claims?.roles[0])
          dispatch(getUserState())
        })
      })
    }
  }, [dispatch])

  return (
    <BrowserRouter>
      <QueryParamProvider ReactRouterRoute={Route}>
        {routes}
      </QueryParamProvider>
    </BrowserRouter>
  )
}

export default App;

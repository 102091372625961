import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { apiGetGrades, apiGetStudentAverageGrades, apiGetStudentDetailedGrades } from '../api/apiCommands'

export const getGradesState = createAsyncThunk(
  'grades/getGradesState',
  async ({token, url}) => {
    return apiGetGrades(token, url)
  }
)

export const getStudentAverageGrades = createAsyncThunk(
  'grades/getStudentAverageGrades',
  async ({token, url}) => {
    return apiGetStudentAverageGrades(token, url)
  }
)

export const getStudentDetailedGrades = createAsyncThunk(
  'grades/getStudentDetailedGrades',
  async ({token, url}) => {
    return apiGetStudentDetailedGrades(token, url)
  }
)

const initialState = {
  totalItems: 0,
  filteredItems: 0,
  items: [],
  students: [],
  status: null,
  error: null
}

const gradesSlice = createSlice({
  name: 'grades',

  initialState,

  reducers: {
    setNewStatus(state, action) {
      state.status = action.payload
    },

    nullifyGrades(state, action) {
      state.items = []
    }
  },

  extraReducers: {
    [getGradesState.pending]: (state, action) => {
      state.status = 'loading'
    },

    [getStudentAverageGrades.pending]: (state, action) => {
      state.status = 'loading'
    },

    [getStudentDetailedGrades.pending]: (state, action) => {
      state.status = 'loading'
    },

    [getGradesState.fulfilled]: (state, action) => {
      const response = action.payload

      state.totalItems = response.totalItems
      state.filteredItems = response.filteredItems
      state.items = response.items
      state.students = response.students
      state.status = 'success'
      state.error = null
    },

    [getStudentAverageGrades.fulfilled]: (state, action) => {
      const response = action.payload

      state.totalItems = response.totalItems
      state.filteredItems = response.filteredItems
      state.items = response.items
      state.status = 'success'
      state.error = null
    },

    [getStudentDetailedGrades.fulfilled]: (state, action) => {
      const response = action.payload

      state.totalItems = response.totalItems
      state.filteredItems = response.filteredItems
      state.items = response.items
      state.status = 'success'
      state.error = null
    },

    [getGradesState.rejected]: (state, action) => {
      state.status = 'failed'
      state.error = action.error.message
    },

    [getStudentAverageGrades.rejected]: (state, action) => {
      state.status = 'failed'
      state.error = action.error.message
    },

    [getStudentDetailedGrades.rejected]: (state, action) => {
      state.status = 'failed'
      state.error = action.error.message
    },
  }
})

export default gradesSlice.reducer
export const { setNewStatus, nullifyGrades } = gradesSlice.actions
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { apiGetTeachers } from '../api/apiCommands'

export const getTeachersState = createAsyncThunk(
  'teachers/getTeachersState',
  async ({token, url}) => {
    return apiGetTeachers(token, url)
  }
)

const initialState = {
  totalTeachers: 0,
  filteredTeachers: 0,
  items: [],
  status: null,
  error: null
}

const teacherSlice = createSlice({
  name: 'teachers',

  initialState,
  
  reducers: {
    setNewStatus(state, action) {
      state.status = action.payload
    },

    setNewItems(state, action) {
      state.items = action.payload
    },

    nullifyTeachers(state) {
      state.totalTeachers = initialState.totalTeachers
      state.items = initialState.items
      state.status = initialState.status
      state.error = initialState.error
    }
  },

  extraReducers: {
    [getTeachersState.pending]: (state, action) => {
      state.status = 'loading'
    },

    [getTeachersState.fulfilled]: (state, action) => {
      const response = action.payload

      state.totalTeachers = response.totalItems
      state.filteredTeachers = response.filteredItems
      state.items = response.items
      state.status = 'success'
      state.error = null
    },

    [getTeachersState.rejected]: (state, action) => {
      state.status = 'failed'
      state.error = action.error.message
    },
  }
})

export default teacherSlice.reducer
export const { setNewStatus, nullifyTeachers, setNewItems } = teacherSlice.actions

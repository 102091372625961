import React from 'react'
import { UserAgentApplication } from "msal"
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'

import { azureConfig } from '../../../azure/azureConfig'
import { nullifyUserState } from '../../../redux/userSlice'

export const LogoutButton = ({className = ""}) => {
  const { t } = useTranslation()
  const userAgentApplication = new UserAgentApplication(azureConfig)
  const dispatch = useDispatch()

  const logoutHandler = async () => {
    localStorage.clear()
    userAgentApplication.logout()
    dispatch(nullifyUserState)
  }

  return (
    <button className={className} onClick={logoutHandler}>
      {t(`signOut`)}
    </button>
  )
}
import React from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'

import { AdminPage } from '../pages/adminPage'

export const useAdminlRoutes = () => {
  return (
    <Switch>
      <Route path="/admin/teachers">
        <AdminPage />
      </Route>
      <Route path="/admin/timetable">
        <AdminPage />
      </Route>
      <Route path="/admin/academic-year">
        <AdminPage />
      </Route>
      <Route path="/admin/class">
        <AdminPage />
      </Route>
      <Route path="/admin/salary">
        <AdminPage />
      </Route>
      <Route path="/admin/lessons">
        <AdminPage />
      </Route>
      <Route path="/admin/module">
        <AdminPage />
      </Route>
      <Route exact path="/admin/holidays">
        <AdminPage />
      </Route>
      <Route path="/admin/notifications">
        <AdminPage />
      </Route>
      <Route path="/admin/students">
        <AdminPage />
      </Route>
      <Route path="/admin/newtimetable">
        <AdminPage />
      </Route>
      <Route path="/admin/grades">
        <AdminPage />
      </Route>
      <Route exact path="/admin/attendance">
        <AdminPage />
      </Route>
      <Route path="/admin/competence">
        <AdminPage />
      </Route>
      <Route path="/admin/proof/:proofId">
        <AdminPage />
      </Route>
      <Route path="/admin/assessment/:proofId">
        <AdminPage />
      </Route>
      <Route path="/admin/report">
        <AdminPage />
      </Route>
      <Route path="/admin/invoice">
        <AdminPage />
      </Route>
      <Route path="/admin/declines">
        <AdminPage />
      </Route>
      <Redirect to="/admin/students"/>
    </Switch>
  )
}

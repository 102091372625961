import React from 'react'
import {Switch, Route, Redirect} from 'react-router-dom'

import { TeacherPage } from '../pages/teacherPage'

export const useTeacherRoutes = () => {  
  return (
    <Switch>
      <Route path="/teacher/timetable">
        <TeacherPage />
      </Route>
      <Route path="/teacher/notifications">
        <TeacherPage />
      </Route>
      <Route path="/teacher/grades">
        <TeacherPage />
      </Route>
      <Route path="/teacher/attendance">
        <TeacherPage />
      </Route>
      <Route path="/teacher/competence">
        <TeacherPage />
      </Route>
      <Route path="/teacher/salary">
        <TeacherPage />
      </Route>
      <Route path="/teacher/class">
        <TeacherPage />
      </Route>
      <Route path="/teacher/attendance-log">
        <TeacherPage />
      </Route>
      <Route path="/teacher/lessons">
        <TeacherPage />
      </Route>
      <Route path="/teacher/profile/">
        <TeacherPage />
      </Route>
      <Route path="/teacher/dashboard">
        <TeacherPage />
      </Route>
      <Route path="/teacher/proof/:proofId">
        <TeacherPage />
      </Route>
      <Route path="/teacher/assessment/:proofId">
        <TeacherPage />
      </Route>
      <Route path="/teacher/report">
        <TeacherPage />
      </Route>
      
      <Redirect to="/teacher/timetable" />
    </Switch>
  )
}
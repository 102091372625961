import moment from "moment"
import { ERROR_DETAIL_KEY } from "./constants"

export const getDaysArray = (year, month) => {
  let numDaysInMonth, daysInWeek, daysIndex, index, i, l, daysArray, monthes;

  numDaysInMonth = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
  monthes = moment.monthsShort()
  daysInWeek = moment.weekdaysShort()
  daysIndex = { 'Sun': 0, 'Mon': 1, 'Tue': 2, 'Wed': 3, 'Thu': 4, 'Fri': 5, 'Sat': 6 }
  index = daysIndex[(new Date(year, month - 1, 1)).toString().split(' ')[0]]
  daysArray = [];

  for (i = 0, l = numDaysInMonth[month - 1]; i < l; i++) {
    daysArray.push(`${daysInWeek[index++]}, ${monthes[month - 1]} ${i + 1}`)
    if (index === 7) index = 0
  }

  return daysArray;
}

//--------------- Round time quarter hour ------------ \\

export const roundTimeQuarterHour = (minutes) => {
  return Math.round(minutes / 15) * 15;
}

//--------------- Formation lesson start/end time to string ------------ \\

export const timeToString = (time) => {
  let minutes;
  if (time.minutes === 0) {
    minutes = '00'
  } else {
    minutes = time.minutes
  }
  return `${time.hours}:${minutes}`
}

export const timeToDate = (time) => {
  return new Date(null, null, null, time.hours, time.minutes, null, null);
}


// ------------ Formation of API links for filtering and / or sorting ------------ \\

export const makeQueryParamsUrl = (obj) => {
  let str = []

  for (let p in obj) 
    if (obj.hasOwnProperty(p)) {
      if (Array.isArray(obj[p])) {
        obj[p].forEach((item) => {
          str.push(encodeURIComponent(p) + "=" + item)
        })
      } else {
        str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]))
      }
    }
  
  return str.join("&")
}

// ------------ Forming an options array for translatable elements ------------ \\

export const makeOptionsList = (t, options = []) => {
  return options.map((option) => {
    const cantons = option.cantons
    if (cantons) {
      return {value: option.value, localizationKey: option.localizationKey, cantons, label: t(`selectorOptions.${option.localizationKey}`)}
    } else {
      return {value: option.value, localizationKey: option.localizationKey, label: t(`selectorOptions.${option.localizationKey}`)}
    }
  })
}

// ------------ Forming an options array for not-translatable elements ------------ \\

export const makeCustomList = (options = []) => {
  return options.map((option) => {
    if (option.id && option.title) {
      return {value: option.id, label: option.title, type: option?.type, start: option?.start, end: option?.end,}
    } else if (option.firstName && option.lastName) {
      return {value: option.id, label: `${option.firstName} ${option.lastName}`}
    } else if (option.value && option.code) {
      return {value: option.value, label: option.code}
    }else if (option.value && option.name) {
      return {value: option.value, label: option.name, classGroups: option.classGroups}
    }
    else if (option.value && option.title) {
      return {value: option.value, label: option.title}
    }
    else if (option.id && option.name && option.semester) {
      return {value: option.id, label: option.name, semesterId: option.semester.id}
    }

    return {value: option.id, label: option.name, type: option.type}
  })
}

export const makeModulesSelectList = (modules = []) => {
  return modules.map((module) => {
    return {
      value: module?.id,
      label: `${module?.name} (${module?.semester?.title})`,
    }
  })
}

export const makeProductsSelectList = (products = []) => {
  return products.map((product) => {
    if (product?.product) {
      return {value: product.product.id, label: product.product.name, price: product.product.price }
    }
    return {value: product.id, label: product.name, price: product.price }
  })
}

export const makeFullSemesterSelectList = (options = []) => {
  return options.map((option) => {
    return {value: option.id, label: option.title, minDate: option.start, maxDate: option.end, type: option.type }
  })
}

export const makeTagsList = (options = []) => {
  return options.map((option) => {
    return {value: option.name, label: option.name}
  })
}

export const makeLocalCustomList = (options = []) => {
  return options.map((option) => {
    if (option.name) {
      return {value: option.value, label: option.name}
    } else if (option.fullName) {
      return {value: option.value, label: option.fullName}
    }
    return {value: option.value, label: option.title}
  })
}

export const makeSelectSubjectsList = (options = []) => {
  return options.map((option) => {
    return {value: option.id, label: option.code}
  })
}

export const makeCustomClassGroupsList = (options = [], classId = '') => {
  let result = []

  if (options && options.length) {
    let filterValue = options.filter(item => item.value === classId)[0]

    if (filterValue) {
      result = filterValue.classGroups
    }
  }

  return result
}

export const makeTeachersSelectList = (teachers = []) => {
  return teachers.map((teacher) => {
    return {value: teacher.id, label: `${teacher?.firstName} ${teacher?.lastName}`}
  })
}

export const makeTeachersIdsList = (teachers = []) => {
  return teachers.map((teacher) => {
    const teacherId = teacher?.id ? teacher.id : teacher?.value

    return teacherId
  })
}

export const makeCustomTeachersList = (options = []) => {
  return options.map((option) => {
    return {value: option.value, label: option.fullName}
  })
}

// ------------ Sort util functions ------------ \\

export const sortFunction = (a, b) => {
  const aLabel = typeof a === 'number' ? a : a.label.toLowerCase()
  const bLabel = typeof b === 'number' ? b : b.label.toLowerCase()

  if (aLabel < bLabel)
    return -1
  if (aLabel > bLabel)
    return 1
  return 0
}

export const moveToStartByLabel = (a, label) => {
  if (a.label === label)
    return -1
  return 0
}

export const universalSortFunction = (a, b, sortName = "name", sortDirection = 1) => {
  const aValue = sortName === 'date' ? new Date(a[sortName]) : String(a[sortName]).toLowerCase()
  const bValue = sortName === 'date' ? new Date(b[sortName]) : String(b[sortName]).toLowerCase()

  if (aValue > bValue) {
    return 1 * sortDirection;
  }
  if (aValue < bValue) {
    return -1 * sortDirection;
  }
  return 0;
}

// ------------ Days of week SelectList ------------ \\

export const returnDaysOfWeekList = (t) => {
  return (
    [
      {
        value: 1,
        label: t(`weekDays.Monday`)
      },
      {
        value: 2,
        label: t(`weekDays.Tuesday`)
      }, 
      {
        value: 3,
        label: t(`weekDays.Wednesday`)
      },
      {
        value: 4,
        label: t(`weekDays.Thursday`)
      },
      {
        value: 5,
        label: t(`weekDays.Friday`)
      },
    ]
  )
}

// ------------ Horizontal scroll by mouse ------------ \\

export const scrollHorizontally = (evt, nodeElement) => {
  evt = window.event || evt
  var delta = Math.max(-1, Math.min(1, (evt.wheelDelta || -evt.detail)))
  nodeElement.scrollLeft -= (delta * 40)
  evt.preventDefault()
}

// ------------ Remove empty fields from object ------------ \\

export const removeEmptyFromObject = (obj) => {
  return Object.fromEntries(Object.entries(obj).filter(([_, v]) => (v != null && v !== 'null')));
}

// ------------ Returns a new color ------------ \\

export const shadeColor = (color, percent) => {
  var R = parseInt(color.substring(1,3),16);
  var G = parseInt(color.substring(3,5),16);
  var B = parseInt(color.substring(5,7),16);

  R = parseInt(R * (100 + percent) / 100);
  G = parseInt(G * (100 + percent) / 100);
  B = parseInt(B * (100 + percent) / 100);

  R = (R<255)?R:255;  
  G = (G<255)?G:255;  
  B = (B<255)?B:255;  

  var RR = ((R.toString(16).length===1)?"0"+R.toString(16):R.toString(16));
  var GG = ((G.toString(16).length===1)?"0"+G.toString(16):G.toString(16));
  var BB = ((B.toString(16).length===1)?"0"+B.toString(16):B.toString(16));

  return "#"+RR+GG+BB;
}

// ------------ Returns range ------------ \\

export const makeRange = (start, end) => {
  let length = end - start + 1
  return Array.from({ length }, (_, index) => index + start)
}

// ------------ Returns detailed error message ------------ \\

export const makeNewErrorMessage = (errorResponse, t) => {
  const { error, lessonTemplate, affectedTeacher, semester } = errorResponse


  let newMessage = t(`errors.${error}`)

  if (lessonTemplate) {
    const keyOfError = ERROR_DETAIL_KEY[error]
    const lessonStartHours = lessonTemplate?.startTime?.hours
    const lessonStartMinutes = lessonTemplate?.startTime?.minutes
    const lessonEndHours = lessonTemplate?.endTime?.hours
    const lessonEndMinutes = lessonTemplate?.endTime?.minutes
    const templateClass = lessonTemplate?.class?.name
    const templateModule = lessonTemplate?.module?.name
    const startTime = `${lessonStartHours >= 10 ? lessonStartHours : '0' + lessonStartHours}:${lessonStartMinutes >= 10 ? lessonStartMinutes : '0' + lessonStartMinutes}`
    const endTime = `${lessonEndHours >= 10 ? lessonEndHours : '0' + lessonEndHours}:${lessonEndMinutes >= 10 ? lessonEndMinutes : '0' +lessonEndMinutes}`
    const reasonName = keyOfError === ERROR_DETAIL_KEY.TEACHER_IS_BUSY ? 
      `${errorResponse[keyOfError]?.firstName} ${errorResponse[keyOfError]?.lastName}` : errorResponse[keyOfError]?.name

    newMessage = `${reasonName} ${t(`errors.${error}`)} ${t('timetablePage.at')} ${startTime} - ${endTime} 
      ${t('timetablePage.by')}    ${templateClass ? `${templateClass} ${t('timetablePage.class')}` : `${templateModule} ${t('timetablePage.module')}`}`;

  }
  else if (affectedTeacher && !lessonTemplate) {
    const keyOfError = ERROR_DETAIL_KEY[error]
    const reasonName = keyOfError === ERROR_DETAIL_KEY.TEACHER_IS_BUSY ? 
      `${errorResponse[keyOfError]?.firstName} ${errorResponse[keyOfError]?.lastName}` : errorResponse[keyOfError]?.name

    newMessage = `${reasonName} ${t(`errors.${error}`)}`
  }

  if (semester) {
    const reasonName = semester?.title
    newMessage = `${reasonName} ${t(`errors.${error}`)}`
  }
  return newMessage
}

// ------------ Events compare ------------ \\

export const compareEvents = (firstEvent, secondEvent) => {
  if (firstEvent.timestamp > secondEvent.timestamp) {
    return 1;
  }

  if (firstEvent.timestamp < secondEvent.timestamp) {
    return -1;
  }

  if (firstEvent.timestamp === secondEvent.timestamp && firstEvent.type.value === 1) {
    return -1;
  }

  return 0;
}

// ------------ Items by height ------------ \\

export const getItemsPerPage = (badPageArea, itemHeight) => {
  const deviceHeight = window.screen.height
  const workHeight = deviceHeight >= 1024 ? deviceHeight - badPageArea : deviceHeight - 180

  return Math.floor(workHeight / itemHeight)
}
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { apiGetProfile } from '../api/apiCommands'

export const getProfileState = createAsyncThunk(
  'profile/getProfile',
  async (token) => {
    return apiGetProfile(token)
  }
)

const initialState = {
  profileData: {},
  status: null,
  error: null
}

const profileSlice = createSlice({
  name: 'profile',

  initialState,

  reducers: {
    setNewStatus(state, action) {
      state.status = action.payload
    },

    nullifyRooms(state) {
      state.profileData = initialState.profileData
      state.status = initialState.status
      state.error = initialState.error
    }
  },

  extraReducers: {
    [getProfileState.pending]: (state, action) => {
      state.status = 'loading'
    },

    [getProfileState.fulfilled]: (state, action) => {
      const response = action.payload

      state.profileData = response
      state.status = 'success'
      state.error = null
    },

    [getProfileState.rejected]: (state, action) => {
      state.status = 'failed'
      state.error = action.error.message
    },
  }
})

export default profileSlice.reducer
export const { setNewStatus, nullifyRooms } = profileSlice.actions
import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { Switch, Route, Redirect } from 'react-router-dom'

import { useAdminlRoutes } from './adminRoutes'
import { useTeacherRoutes } from './teacherRoutes'
import { useStudentRoutes } from './studentRoutes'

import { USER_ROLE_MAP } from '../azure/azureConfig'
import { AuthorizationPage } from '../pages/authorizationPage'
import { ProofGlobalPage } from '../pages/globalPage'
import { GratitudePage } from '../pages/gratitudePage'

export const useGlobalRoutes = () => {
  const [message, setMessage] = useState({
    description: '',
    gratitude: '',
  })

  const getKeyByValue = (object, value) => {
    return Object.keys(object).find(key => object[key] === value)
  }

  const userRoleValue = useSelector(state => state.userData.userRole)
  const userRole = getKeyByValue(USER_ROLE_MAP, userRoleValue)

  const userUndefiendRoutes =
    <Switch>
      <Route path="/assessment/:proofId">
        <ProofGlobalPage isSimpleVersion={true} setMessage={setMessage} />
      </Route>
      <Route path="/proof/:proofId">
        <ProofGlobalPage isSimpleVersion={false} setMessage={setMessage} />
      </Route>
      
      {(message.description && message.gratitude) &&
        <Route path="/gratitude">
          <GratitudePage message={message.description} gratitude={message.gratitude} />
        </Route>
      }
      
      <Route path="/">
        <AuthorizationPage />
      </Route>

      <Redirect to="/" />
    </Switch>

  const studentRoutes = useStudentRoutes()
  const teacherRoutes = useTeacherRoutes()
  const adminRoutes = useAdminlRoutes()

  const USER_ROUTES = {
    admin: adminRoutes,
    teacher: teacherRoutes,
    student: studentRoutes,
    userUndefiend: userUndefiendRoutes,
    undefined: userUndefiendRoutes
  }
  
  return (
    USER_ROUTES[userRole]
  )
}

import React, { useCallback, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { UserAgentApplication } from "msal"

import { azureConfig, scopes } from '../azure/azureConfig'
import { getUserState } from '../redux/userSlice'

import { ReactComponent as ReactWavesSvg } from '../assets/waves.svg'
import { ReactComponent as ReactLogoSvg } from '../assets/berit-exbit-logo.svg'

export const AuthorizationPage = () => {
  const {t} = useTranslation()
  const dispatch = useDispatch()
  const location = useLocation()

  const loginHandler = useCallback(() => {
    const userAgentApplication = new UserAgentApplication(azureConfig)

    userAgentApplication.loginPopup({scopes})
    .then((response) => {
      if (response.idToken) {
        localStorage.setItem('userRole', response?.idToken?.claims?.roles)
      }
      dispatch(getUserState())
    })
  }, [dispatch])

  useEffect(() => {
    if (location.hash) {
      loginHandler()
    }
  }, [location, loginHandler])

  return (
    <section className="authorization-section">
      <div className="authorization-section__login-container">
        <div className="authorization-section__salutation">
          <ReactLogoSvg />
          <h1>{t(`authorizationPage.welcome`)}</h1>
          <span>{t(`authorizationPage.signAcc`)}</span>
        </div>
        <button className="button" onClick={loginHandler}>{t(`authorizationPage.signin`)}</button>
      </div>

      <div className="authorization-section__waves-container">
        <ReactWavesSvg />
      </div>
    </section>
  )
}
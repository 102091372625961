import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { apiGetSelectOptions, apiGetSelectStydentTypes } from '../api/apiCommands'

export const getSelectorState = createAsyncThunk(
  'selector/getSelectorState',
  async ({token, type}) => {
    return apiGetSelectOptions(token, type)
  }
)

export const getSelectorStydentTypes = createAsyncThunk(
  'selector/getSelectorStydentTypes',
  async ({token, url}) => {
    return apiGetSelectStydentTypes(token, url)
  }
)

const initialState = {
  countries: [],
  studentTypes: [],
  lessonTypes: [],
  salutations: [],
  declineReasons: [],
  nationalities: [],
  teachers: [],
  attendanceTypes: [],
  subjects: [],
  semesters: [],
  classes: [],
  rooms: [],
  status: null,
  error: null
}

const selectorSlice = createSlice({
  name: 'selector',

  initialState,
  
  reducers: {
    setNewStatus(state, action) {
      state.status = action.payload
    }
  },

  extraReducers: {
    [getSelectorState.pending]: (state, action) => {
      state.status = 'loading'
    },

    [getSelectorStydentTypes.pending]: (state, action) => {
      state.status = 'loading'
    },

    [getSelectorStydentTypes.fulfilled]: (state, action) => {
      const response = action.payload

      if (response.fetchStatus) {
        state.error = 401
      } else {
        state.studentTypes = response
        state.status = 'success'
        state.error = null
      }
    },

    [getSelectorState.fulfilled]: (state, action) => {
      const response = action.payload

      if (response.fetchStatus) {
        state.error = 401
      } else {
        state.countries = response.countries ? response.countries : state.countries
        state.studentTypes = response.educationTypes ? response.educationTypes : state.educationTypes
        state.lessonTypes = response.lessonTypes ? response.lessonTypes : state.lessonTypes
        state.salutations = response.salutations ? response.salutations : state.salutations
        state.declineReasons = response.declineReasons ? response.declineReasons : state.declineReasons
        state.nationalities = response.nationalities ? response.nationalities : state.nationalities
        state.teachers = response.teachers ? response.teachers : state.teachers
        state.attendanceTypes = response.attendancetypes ? response.attendancetypes : state.attendanceTypes
        state.subjects = response.subjects ? response.subjects : state.subjects
        state.semesters = response.semesters ? response.semesters : state.semesters
        state.classes = response.classes ? response.classes : state.classes
        state.rooms = response.rooms ? response.rooms : state.rooms
        state.status = 'success'
        state.error = null
      }
    },

    [getSelectorState.rejected]: (state, action) => {
      state.status = 'failed'
      state.error = action.error.message
    },

    [getSelectorStydentTypes.rejected]: (state, action) => {
      state.status = 'failed'
      state.error = action.error.message
    },
  }
})

export default selectorSlice.reducer
export const { setNewStatus } = selectorSlice.actions